import React from 'react';
import PropTypes from 'prop-types';
import { SORTING_ASC, SORTING_DESC } from 'constants/admin';


const updateStateByProps = (props) => {
  const { sorting, name } = props;
  const isSorted = Object.prototype.hasOwnProperty.call(sorting, name) ? sorting[name] : null;
  const iconName = sorting[name] === SORTING_DESC // eslint-disable-line no-nested-ternary
    ? 'fa-sort-down'
    : sorting[name] === SORTING_ASC
      ? 'fa-sort-up'
      : 'fa-sort';
  return {
    isSorted,
    iconName
  };
};

class AdminUiListSortCellComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.sorting === state.sorting) {
      return null;
    }
    return updateStateByProps(props);
  };

  handleSortClick = () => {
    const { name, handleSort } = this.props;
    handleSort(name);
  };

  render() {
    const { title } = this.props;
    const { isSorted, iconName } = this.state;
    return (
      <th
        onClick={ this.handleSortClick }
        style={ { backgroundColor: isSorted && 'rgba(224, 238, 246, 1)' } }
      >
        <span style={ { marginRight: '10px' } }>{title}</span>
        <i className={ `fa ${iconName}` } />
      </th>
    );
  }
}

AdminUiListSortCellComponent.propTypes = {
  title: PropTypes.string.isRequired,
  sorting: PropTypes.objectOf(PropTypes.oneOf([SORTING_DESC, SORTING_ASC])).isRequired,
  name: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired
};

export default AdminUiListSortCellComponent;
