import React from 'react';

import * as Form from 'admin/components/ui/form/main';
import * as PropTypes from 'prop-types';

const AutocompleteMultiFormGroupReduxFormContainer = ({
  input,
  suggestions,
  label,
  placeholder,
  required: requiredFromProps,
  meta: { touched, error }
}) => (
  <Form.Group
    labelStr={ label }
    error={ !!error && touched }
    errorMsgs={ error }
    required={ requiredFromProps }
  >
    <Form.AutocompleteMulti
      suggestions={ suggestions || [] }
      value={ input.value || '' }
      onSelect={ ({ value }) => input.onChange(value) }
      placeholder={ placeholder }
      name={ input.name }
      multi
    />
  </Form.Group>
);

AutocompleteMultiFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func
  }).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool
  }).isRequired
};

AutocompleteMultiFormGroupReduxFormContainer.defaultProps = {
  placeholder: '',
  required: false
};

export default AutocompleteMultiFormGroupReduxFormContainer;
