import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Select from 'react-select';
import { debounce } from 'lodash';

import * as Form from 'admin/components/ui/form/main';

class SelectAsyncFormGroupReduxFormContainer extends Component {
  constructor(props) {
    super(props);
    this.debouncedLoadOptions = debounce(props.loadOptions, 500);
  }

  render() {
    const {
      input,
      options,
      disabled,
      placeholder,
      label,
      meta: { touched, error },
      required,
      labelKey,
      valueKey
    } = this.props;
    return (
      <Form.Group
        labelStr={ label }
        error={ !!error && touched }
        errorMsgs={ error }
        required={ required }
      >
        <Select.Async
          { ...input }
          onBlur={ () => { input.onBlur(input.value); } }
          value={ input.value }
          valueKey={ valueKey }
          labelKey={ labelKey }
          options={ options }
          loadOptions={ this.debouncedLoadOptions }
          disabled={ disabled }
          placeholder={ placeholder }
        />
      </Form.Group>
    );
  }
}

SelectAsyncFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.any,
  options: PropTypes.any,
  disabled: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  meta: PropTypes.any,
  required: PropTypes.any,
  labelKey: PropTypes.any,
  valueKey: PropTypes.any,
  loadOptions: PropTypes.any
};

SelectAsyncFormGroupReduxFormContainer.defaultProps = {
  placeholder: ''
};

export default SelectAsyncFormGroupReduxFormContainer;
