import PropTypes from 'prop-types';
import React from 'react';

const AdminUiFormCheckboxComponent = ({
  name, onChange, checked, checkboxText, disabled
}) => (
  <div className="checkbox-box">
    <label>
      {checkboxText && `${checkboxText} ` }
      <input
        style={ { marginLeft: checkboxText && 10, transform: checkboxText && 'translateY(2px)' } }
        name={ name }
        checked={ checked }
        disabled={ disabled }
        type="checkbox"
        onChange={ onChange }
      />
    </label>
  </div>
);

AdminUiFormCheckboxComponent.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checkboxText: PropTypes.string,
  disabled: PropTypes.bool,
};

AdminUiFormCheckboxComponent.defaultProps = {
  checked: false,
  checkboxText: '',
  disabled: false,
};

export default AdminUiFormCheckboxComponent;
