import React from 'react';
import PropTypes from 'prop-types';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminTmsDashboardMatchContainer from './match';

import { i18n } from 'core/services/i18n';

export default class AdminTmsDashboardMatchesContainer extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired
  }

  constructor() {
    super();

    this.state = {
      isFetching: true,
      matches: []
    };
  }

  componentDidMount() {
    this.updateList();
  }

  getMatches = () => {
    const { url } = this.props;
    return $.ajax({
      url,
      dataType: 'json',
      cache: false
    });
  }

  updateList = () => {
    this.getMatches().done((result) => {
      this.setState({
        matches: result,
        isFetching: false
      });
    });
  }

  render() {
    const {
      isFetching,
      matches
    } = this.state;

    if (isFetching) { return <AdminUiIconComponent spin />; }
    if (matches.length === 0) { return (<div>No matches</div>); }

    return (
      <table className="table table-condensed">
        <thead>
          <tr>
            <th>{ i18n('simple_form.labels.tms_match.id') }</th>
            <th>{ i18n('simple_form.labels.tms_match.started_at') }</th>
            <th>{ i18n('simple_form.labels.tms_match.group') }</th>
            <th colSpan="3" />
            <th>{ i18n('simple_form.labels.tms_match.referee') }</th>
            <th>{ i18n('simple_form.labels.tms_match.status') }</th>
            <th>{ i18n('simple_form.labels.tms_match.call_referee_source') }</th>
            <th />
          </tr>
        </thead>
        <tbody>
          { matches.map(match => <AdminTmsDashboardMatchContainer key={ match.id } { ...match } />) }
        </tbody>
      </table>
    );
  }
}
