
export const SET_LOCALE = 'SET_LOCALE';
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_TZ = 'Europe/Kiev';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const FLAGS_CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/';

// @DEPRECATED user admin constants from admin.js
export const ITEMS_PER_PAGE = [10, 25, 50, 100, 200];
export const ADMIN_PER_PAGE = 'admin_per_page';
