import { teamsList } from 'constants/admin/teams';
import fetchApi from 'admin/api/api';
import deleteEmptyFields from 'admin/helpers/utils/delete_empty_fields';
import { COMMON_ERR } from 'constants/admin';

// list
const fetchTeamsStart = () => ({ type: teamsList.FETCH_TEAMS_START });
const fetchTeamsOk = payload => ({ type: teamsList.UPDATE_TEAMS, payload });
const fetchTeamsFail = err => ({ type: teamsList.FETCH_TEAMS_FAIL, payload: err });
export const fetchTeamsList = () => async (dispatch, getState) => {
  const {
    adminTeamsList:
      { filter, sort, pagination },
    admin:
      { perPage },
    intl: { locale }
  } = getState();
  const sortingKey = Object.keys(sort)[0];
  const filterObj = deleteEmptyFields(filter);
  const queryParams = {
    ...filterObj,
    sort: sortingKey,
    direction: sort[sortingKey],
    admin_per_page: perPage,
    page: pagination.page
  };
  dispatch(fetchTeamsStart());
  try {
    const data = await fetchApi(`/${locale}/teams.json`, { queryParams, historyReplaceState: true });
    const payload = {
      teams: data.result,
      total: data.total,
      pages: Math.ceil(data.total / pagination.adminPerPage),
      isLoadingTeams: false
    };
    dispatch(fetchTeamsOk(payload));
  } catch (e) {
    const { error, networkError } = e;
    let errData = error;
    if (Array.isArray(error)) {
      errData = error.join('');
    } else {
      errData = networkError;
    }
    dispatch(fetchTeamsFail(errData || COMMON_ERR));
  }
};


// pagination
export const changePage = page => ({ type: teamsList.CHANGE_PAGE, payload: page });

// filters
export const updateFilter = newFilter => ({ type: teamsList.UPDATE_FILTER, payload: newFilter });
/**
 * Reset all filters; iterate by filter object and set value of all fields equal empty string;
 * dispatch action RESET_ADMIN_TEAMS_LIST_FILTER with object that contains empty filter
 * @returns {Function}
 */
export const resetFilter = () => (dispatch, getState) => {
  const { adminTeamsList: { filter } } = getState();
  const discardedFilter = { ...filter };
  Object.keys(discardedFilter).map((key) => {
    if (discardedFilter[key]) {
      discardedFilter[key] = '';
    }
  });
  dispatch({ type: teamsList.RESET_FILTER, payload: discardedFilter });
};

// sort
export const updateSorting = newSorting => ({ type: teamsList.UPDATE_SORTING, payload: newSorting });
export const resetSorting = () => ({ type: teamsList.RESET_SORTING });

// regions
export const fetchRegionsStart = regions => ({ type: teamsList.FETCH_REGIONS_START, payload: regions });
export const fetchRegionsOk = regions => ({ type: teamsList.FETCH_REGIONS_OK, payload: regions });
export const fetchRegionsFail = err => ({ type: teamsList.FETCH_REGIONS_FAIL, payload: err });
export const fetchRegions = disciplineId => async (dispatch, getState) => {
  const { intl: { locale } } = getState();
  dispatch(fetchRegionsStart());
  try {
    const data = await fetchApi(`/${locale}/disciplines/${disciplineId}/regions`);
    dispatch(fetchRegionsOk(data));
  } catch (e) {
    const { error, networkError } = e;
    dispatch(fetchRegionsFail(error || networkError || COMMON_ERR));
  }
};

// disciplines
export const fetchDisciplinesStart = () => ({ type: teamsList.FETCH_DISCIPLINES_START });
export const fetchDisciplinesOk = payload => ({ type: teamsList.FETCH_DISCIPLINES_OK, payload });
export const fetchDisciplinesFail = payload => ({ type: teamsList.FETCH_DISCIPLINES_FAIL, payload });
export const fetchDisciplines = () => async (dispatch, getState) => {
  const { intl: { locale } } = getState();
  const queryApiJson = {
    fields: {
      discipline: ['alias', 'name']
    }
  };
  dispatch(fetchDisciplinesStart());
  try {
    const data = await fetchApi(`/${locale}/disciplines`, { queryApiJson });
    dispatch(fetchDisciplinesOk(data));
  } catch (e) {
    const { error, networkError } = e;
    dispatch(fetchDisciplinesFail(error || networkError || COMMON_ERR));
  }
};
