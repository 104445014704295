import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import AdminUiFormCheckboxComponent from 'admin/components/ui/form/checkbox';
import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminUiIconLoaderComponent from 'admin/components/ui/icon_loader';

const FIELDS_NAME = {
  PLAYER_ID: 'playerId',
  NO_SHOW: 'noShow',
  KILLS: 'kills',
  BONUS_KILL_POINTS: 'bonusKillPoints',
  BONUS_POINTS: 'bonusPoints',
  DAMAGE_DEALT: 'damageDealt'
};

class AdminTmsTournamentBattleResultContainer extends React.Component {
  static propTypes = {
    showPopups: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    sortUrl: PropTypes.string.isRequired,
    isEarningMoney: PropTypes.bool.isRequired,
    isAccountNoShows: PropTypes.bool.isRequired,
    players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.name
    })),
    position: PropTypes.number.isRequired,
    playerId: PropTypes.number,
    noShow: PropTypes.bool,
    kills: PropTypes.number,
    damageDealt: PropTypes.number,
    positionPoints: PropTypes.number,
    killsPoints: PropTypes.number,
    bonusKillPoints: PropTypes.number,
    bonusPoints: PropTypes.number,
    moneyDiff: PropTypes.number,
    eloPoints: PropTypes.number,
    extras: PropTypes.any,
    onChangedResult: PropTypes.func.isRequired
  };

  static defaultProps = {
    players: [],
    playerId: null,
    noShow: false,
    kills: 0,
    damageDealt: 0,
    positionPoints: 0,
    killsPoints: 0,
    bonusKillPoints: 0,
    bonusPoints: 0,
    moneyDiff: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: null,
      errors: {},
      position: props.position,
      playerId: props.playerId,
      noShow: props.noShow,
      kills: props.kills,
      damageDealt: props.damageDealt,
      positionPoints: props.positionPoints,
      killsPoints: props.killsPoints,
      bonusKillPoints: props.bonusKillPoints,
      bonusPoints: props.bonusPoints,
      eloPoints: props.eloPoints,
      moneyDiff: props.moneyDiff,
      extras: props.extras,
      url: props.url,
      // eslint-disable-next-line react/no-unused-state
      sortUrl: props.sortUrl
    };
  }

  componentDidUpdate(prevProps) {
    const { position } = this.props;

    if (position !== prevProps.position) {
      this.changeScoreDataInState();
    }
  }

  checkFinishBtnStatus () {
    $('#js-finish-btn').attr('disabled', !$('select[name=playerId]').toArray().some(el => !!el.value));
  }

  getRequestParams = (fieldName, fieldValue, previousPlayerId) => {
    const {
      playerId,
      noShow,
      kills,
      damageDealt,
      bonusKillPoints,
      bonusPoints,
      eloPoints,
      position,
    } = this.state;
    const {
      showPopups
    } = this.props;

    const data = {
      tms_battle: {
        show_popups: showPopups
      },
      tms_battle_score: {
        player_id: playerId,
        no_show: noShow,
        kills,
        damage_dealt: damageDealt,
        bonus_kill_points: bonusKillPoints,
        bonus_points: bonusPoints,
        eloPoints: eloPoints
      }
    };

    // CREATE
    if (!previousPlayerId) {
      return {
        method: 'POST',
        data: {
          tms_battle_score: {
            position,
            player_id: playerId
          }
        }
      };
    }

    if (previousPlayerId && !fieldValue && fieldName === 'playerId') {
      return { method: 'DELETE', data };
    }

    return { method: 'PUT', data };
  };

  handleChange = (e) => {
    const { url, playerId } = this.state;
    const { onChangedResult } = this.props;
    const { name: fieldName } = e.target;
    let fieldValue;

    // Oh yes. Crunchy crunch. Method initially was not supposed to handle inputs other than numeric
    if (fieldName === FIELDS_NAME.NO_SHOW) {
      fieldValue = e.target.checked;
    } else {
      fieldValue = e.target.value;
    }

    // validation
    if (fieldName === FIELDS_NAME.PLAYER_ID) {
      // do nothing
    } else if (fieldName === FIELDS_NAME.NO_SHOW) {
      // do nothing
    } else if (fieldValue === '' || +fieldValue < 0) {
      this.setState({
        [fieldName]: fieldValue,
        errors: {
          [fieldName]: fieldValue
        }
      });
      return;
    }

    this.setState(
      { [fieldName]: fieldValue, updating: true, errors: {} },
      () => {
        const requestParams = this.getRequestParams(fieldName, fieldValue, playerId);

        $.ajax({
          url,
          type: requestParams.method,
          dataType: 'json',
          data: requestParams.data,
          success: (data) => {
            this.setState({ updating: false, ...data });
            onChangedResult(data);
            this.checkFinishBtnStatus();
          },
          error: (response) => {
            const data = response.responseJSON;
            this.setState({
              updating: null,
              errors: {
                playerId: data.errors.player_id,
                noShow: data.errors.no_show,
                damageDealt: data.errors.damageDealt,
                bonusKillPoints: data.errors.bonusKillPoints,
                bonusPoints: data.errors.bonusPoints,
                kills: data.errors.kills
              }
            });
          }
        });
      }
    );
  };

  changeScoreDataInState = () => {
    const {
      position,
      noShow,
      kills,
      damageDealt,
      positionPoints,
      killsPoints,
      bonusKillPoints,
      bonusPoints,
      extras,
      url,
      moneyDiff,
      sortUrl
    } = this.props;

    this.setState({
      position,
      noShow,
      kills,
      damageDealt,
      positionPoints,
      killsPoints,
      bonusKillPoints,
      bonusPoints,
      extras,
      url,
      moneyDiff,
      // eslint-disable-next-line react/no-unused-state
      sortUrl
    });
  };

  render() {
    const { players, isAccountNoShows, isEarningMoney } = this.props;
    const {
      position,
      errors,
      playerId,
      noShow,
      kills,
      damageDealt,
      positionPoints,
      killsPoints,
      bonusKillPoints,
      bonusPoints,
      eloPoints,
      moneyDiff,
      extras,
      updating
    } = this.state;

    const fieldsDisabled = playerId === null || noShow;

    return (
      <tr>
        <td>
          #
          { position }
        </td>
        <td className={ classnames({ 'has-error': errors.playerId !== undefined }) }>
          <select
            className="form-control"
            name={ FIELDS_NAME.PLAYER_ID }
            defaultValue={ playerId }
            onChange={ this.handleChange }
          >
            <option key="all" />
            { players.map(player => <option key={ player.id } value={ player.id }>{ player.name }</option>) }
          </select>
        </td>
        {
          isAccountNoShows
            && (
              <td>
                <AdminUiFormCheckboxComponent
                  name={ FIELDS_NAME.NO_SHOW }
                  checked={ noShow }
                  disabled={ playerId === null }
                  onChange={ this.handleChange }
                />
              </td>
            )
        }
        <td className={ classnames({ 'has-error': errors.kills !== undefined }) }>
          <input
            className="form-control"
            type="number"
            name={ FIELDS_NAME.KILLS }
            disabled={ fieldsDisabled }
            value={ kills }
            onChange={ this.handleChange }
          />
        </td>
        <td className={ classnames({ 'has-error': errors.damageDealt !== undefined }) }>
          <input
            className="form-control"
            type="number"
            name={ FIELDS_NAME.DAMAGE_DEALT }
            disabled={ fieldsDisabled }
            value={ damageDealt }
            onChange={ this.handleChange }
          />
        </td>
        <td className={ classnames({ 'has-error': errors.bonusKillPoints !== undefined }) }>
          <input
            className="form-control"
            type="number"
            name={ FIELDS_NAME.BONUS_KILL_POINTS }
            disabled={ fieldsDisabled }
            value={ bonusKillPoints }
            onChange={ this.handleChange }
          />
        </td>
        <td>{ positionPoints }</td>
        <td>{ killsPoints }</td>
        <td className={ classnames({ 'has-error': errors.bonusPoints !== undefined }) }>
          <input
            className="form-control"
            type="number"
            name={ FIELDS_NAME.BONUS_POINTS }
            disabled={ fieldsDisabled }
            value={ bonusPoints }
            onChange={ this.handleChange }
          />
        </td>
        <td>{ positionPoints + killsPoints + bonusPoints }</td>
        {
          isEarningMoney
            && (
              <td>
                { moneyDiff > 0 && '+' }
                { moneyDiff }
              </td>
            )
        }
        <td>
          {
            Object.entries(extras).map(([key, value]) => {
              return (
                <span>
                  {key}: {JSON.stringify(value)}
                  <br />
                </span>
              );
            })
          }
          elo points: { eloPoints }
        </td>
        <td>
          { updating === true && <AdminUiIconLoaderComponent /> }
          { updating === false && <AdminUiIconComponent mod="checkmark" className="text-success" /> }
        </td>
      </tr>
    );
  }
}

export default AdminTmsTournamentBattleResultContainer;
