import PropTypes from 'prop-types';
import React from 'react';

import AdminTmsTournamentBattleSeedingPlayerContainer from './battle_seeding_player';

class AdminTmsTournamentBattleSeedingPlayersContainer extends React.Component {
  static propTypes = {
    seedingPlayers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    battleSeedingPlayers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      seeding_player_id: PropTypes.number.isRequired
    })).isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number,
      position: PropTypes.number.isRequired,
      positionPoints: PropTypes.number.isRequired,
      playerId: PropTypes.number,
      kills: PropTypes.number.isRequired,
      killsPoints: PropTypes.number.isRequired,
      sortUrl: PropTypes.string
    }))
  };

  static defaultProps = {
    scores: []
  };

  constructor(props) {
    super(props);
    this.state = {
      battleSeedingPlayers: props.battleSeedingPlayers,
      scores: props.scores.sort((a, b) => a.position - b.position)
    };
  }

  changeResult = (score) => {
    // TODO: Список доступных участников, которые еще не в битве
    // this.setState(prevState => ({
    //   scores: prevState.scores.map((item) => {
    //     if (item.position === score.position) return { ...item, ...score };
    //     return item;
    //   })
    // }));
  };

  render() {
    const { battleSeedingPlayers } = this.state;
    const { seedingPlayers } = this.props;

    return (
      <div className="row">
        {
          battleSeedingPlayers.map((battleSeedingPlayer, index) => (
            <div className="col-sm-6 col-md-4">
              <AdminTmsTournamentBattleSeedingPlayerContainer
                index={ index }
                seedingPlayerId={ battleSeedingPlayer.seedingPlayerId }
                url={ battleSeedingPlayer.url }
                seedingPlayers={ seedingPlayers }
                onChange={ this.changeResult }
                key={ battleSeedingPlayer.id || index }
                battleSeedingPlayer={ battleSeedingPlayer }
              />
            </div>
          ))
        }
      </div>
    );
  }
}

export default AdminTmsTournamentBattleSeedingPlayersContainer;
