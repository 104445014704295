import React from 'react';
import PropTypes from 'prop-types';

import AdminStatusBadgeComponent from 'admin/components/status_badge';
import AdminUiIconComponent from 'admin/components/ui/icon';
import CoreGlobalCountryFlag from 'core/components/shared/blocks/country_flag';

import { i18n } from 'core/services/i18n';

export default class AdminTmsDashboardMatchContainer extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    started_at: PropTypes.string.isRequired,
    tournament_group: PropTypes.shape({
      label: PropTypes.string
    }),
    player: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      name: PropTypes.string,
      country: PropTypes.object
    }),
    opponent: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      name: PropTypes.string,
      country: PropTypes.object
    }),
    referee: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      name: PropTypes.string
    }),
    status: PropTypes.string.isRequired,
    call_referee_source_url: PropTypes.string,
    protest_source_url: PropTypes.string
  }

  static defaultProps = {
    player: null,
    opponent: null,
    referee: null,
    tournament_group: null,
    call_referee_source_url: null,
    protest_source_url: null
  }

  render() {
    const {
      id,
      started_at,
      tournament_group,
      player,
      opponent,
      referee,
      status,
      call_referee_source_url,
      protest_source_url,
      url
    } = this.props;

    return (
      <tr>
        <td>{ id }</td>
        <td dangerouslySetInnerHTML={ { __html: started_at } } />
        <td>{ tournament_group.label }</td>
        <td className="text-right">
          <a href={ player.url } target="_blank" rel="noopener noreferrer">
            { player && player.name }
          </a>
          &nbsp;
          <CoreGlobalCountryFlag country={ player.country } />
        </td>
        <td className="text-center">vs</td>
        <td className="text-left">
          <CoreGlobalCountryFlag country={ opponent.country } />
          &nbsp;
          <a href={ opponent.url }>
            { opponent && opponent.name }
          </a>
        </td>
        <td>
          <a href={ referee.url } target="_blank" rel="noopener noreferrer">
            { referee && referee.name }
          </a>
        </td>
        <td>
          <AdminStatusBadgeComponent status={ status }>
            { i18n(`tms.match_statuses.${status}`) }
          </AdminStatusBadgeComponent>
        </td>
        <td>
          {
            call_referee_source_url !== null && (
              <a href={ call_referee_source_url } target="_blank" rel="noopener noreferrer">
                <AdminUiIconComponent mod="bell" />
              </a>
            )
          }
          {
            protest_source_url !== null && (
              <a href={ protest_source_url } target="_blank" rel="noopener noreferrer">
                <AdminUiIconComponent mod="warning" />
              </a>
            )
          }
        </td>
        <td>
          <a href={ url } className="btn btn-default pull-right" target="_blank" rel="noopener noreferrer">
            { i18n('admin.tms.dashboard.matches.manage') }
          </a>
        </td>
      </tr>
    );
  }
}
