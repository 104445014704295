import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class AdminUiFormInlineComponent extends React.Component {
  static propTypes = {
    labelStr: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    error: PropTypes.bool,
    required: PropTypes.bool
  }

  static defaultProps = {
    error: false,
    required: false
  }

  render() {
    return (
      <label className={ classNames('form-label col-md-12', { 'has-error': this.props.error }) }>
        <p className="col-md-2">
          { this.props.required && <span><abbr title="Required">*</abbr>&nbsp;</span> }
          { this.props.labelStr }
        </p>
        <div className='col-md-10'>
          { this.props.children }
        </div>
      </label>
    );
  }
}
