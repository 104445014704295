import PropTypes from 'prop-types';
import React from 'react';
import {
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc';

import { i18n } from 'core/services/i18n';
import arrayMove from 'array-move';

import AdminTmsTournamentBattleResultContainer from './battle_result';

const SortableItem = SortableElement((params) => {
  const {
    result,
    players,
    onChangedResult,
    isEarningMoney,
    isAccountNoShows,
    showPopups,
  } = params;

  return (
    <AdminTmsTournamentBattleResultContainer
      showPopups={ showPopups }
      players={ players }
      onChangedResult={ onChangedResult }
      isEarningMoney={ isEarningMoney }
      isAccountNoShows={ isAccountNoShows }
      { ...result }
    />
  );
});

const SortableList = SortableContainer(({ children }) => <tbody>{ children }</tbody>);

class AdminTmsTournamentBattleResultsContainer extends React.Component {
  static propTypes = {
    isEarningMoney: PropTypes.bool.isRequired,
    isAccountNoShows: PropTypes.bool.isRequired,
    players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.name
    })),
    showPopups: PropTypes.bool.isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number,
      position: PropTypes.number.isRequired,
      positionPoints: PropTypes.number.isRequired,
      playerId: PropTypes.number,
      noShow: PropTypes.bool,
      kills: PropTypes.number.isRequired,
      killsPoints: PropTypes.number.isRequired,
      bonusKillPoints: PropTypes.number.isRequired,
      bonusPoints: PropTypes.number.isRequired,
      damageDealt: PropTypes.number.isRequired,
      eloPoints: PropTypes.number,
      moneyDiff: PropTypes.number,
      extras: PropTypes.any,
      sortUrl: PropTypes.string
    }))
  };

  static defaultProps = {
    players: [],
    scores: []
  };

  constructor(props) {
    super(props);
    this.state = {
      showPopups: props.showPopups,
      scores: props.scores.sort((a, b) => a.position - b.position)
    };
  }

  getAvailablePlayers = (filteredPlayers, currentPlayerId) => {
    const { players } = this.props;
    const currentPlayer = players.find(item => currentPlayerId === item.id);

    return currentPlayer ? [currentPlayer, ...filteredPlayers] : filteredPlayers;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const { scores, showPopups } = this.state;
    const item = scores[oldIndex];

    this.setState(prevState => ({
      scores: arrayMove(prevState.scores, oldIndex, newIndex)
    }));

    $.ajax({
      url: item.sortUrl,
      type: 'PUT',
      dataType: 'json',
      data: {
        show_popups: showPopups,
        new_position: newIndex + 1,
        old_position: oldIndex + 1
      },
      success: (data) => {
        this.setState({
          scores: data.sort((a, b) => a.position - b.position)
        });
      }
    });
  };

  changeResult = (score) => {
    this.setState(prevState => ({
      scores: prevState.scores.map((item) => {
        if (item.position === score.position) return { ...item, ...score };
        return item;
      })
    }));
  };

  changeShowPopups = () => {
    this.setState(prevState => ({
      showPopups: !prevState.showPopups
    }));
  }

  render() {
    const { scores, showPopups } = this.state;
    const { players, isEarningMoney, isAccountNoShows } = this.props;
    const filteredPlayers = players.filter(item => !scores.find(s => s.playerId === item.id));

    return (
      <div>
        <div className="form-group boolean">
          <div className="checkbox">
            <label htmlFor="show_popups">
              <input
                type="checkbox"
                id="js-show_popups"
                name="show_popups"
                checked={ showPopups }
                onChange={ this.changeShowPopups }
              />
              Show Popups
            </label>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>{ i18n('simple_form.labels.tms_battle_score.position') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.player') }</th>
              {
              isAccountNoShows
                && <th>{ i18n('simple_form.labels.tms_battle_score.no_show') }</th>
            }
              <th>{ i18n('simple_form.labels.tms_battle_score.kills') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.damage_dealt') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.bonus_kill_points') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.position_points') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.kills_points') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.bonus_points') }</th>
              <th>{ i18n('simple_form.labels.tms_battle_score.total_points') }</th>
              {
              isEarningMoney
                && <th>{ i18n('simple_form.labels.tms_battle_score.money_diff') }</th>
            }
              <th>{ i18n('simple_form.labels.tms_battle_score.extras') }</th>
            </tr>
          </thead>

          <SortableList onSortEnd={ this.onSortEnd }>
            { scores.map((result, index) => (
              <SortableItem
                showPopups={ showPopups }
                disabled={ result.id === null }
                key={ result.id || result.position }
                index={ index }
                result={ result }
                isEarningMoney={ isEarningMoney }
                isAccountNoShows={ isAccountNoShows }
                players={ this.getAvailablePlayers(filteredPlayers, result.playerId) }
                onChangedResult={ this.changeResult }
              />
            )) }
          </SortableList>
        </table>
      </div>
    );
  }
}

export default AdminTmsTournamentBattleResultsContainer;
