import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminUiFormUserPreviewComponent from './user_preview';
import UserAutocomplete from './users_autocomplete';

class AdminUiFormAutocompleteUsersComponent extends Component {
  handleSuggestionSelected = ({ value = null }) => {
    const { onChangeUser } = this.props;
    onChangeUser(value);
  }

  handleRemoveUser = () => {
    const { onRemoveUser, onChangeUser } = this.props;
    onRemoveUser();
    onChangeUser(null);
  }

  render() {
    const {
      user, name, placeholder
    } = this.props;
    return (
      <React.Fragment>
        { user && (
          <AdminUiFormUserPreviewComponent
            onRemoveUser={ this.handleRemoveUser }
            user={ user }
          />
        ) }
        {!user && (
        <UserAutocomplete
          name={ name }
          placeholder={ placeholder }
          onSuggestionSelected={ this.handleSuggestionSelected }
        />
        )}
      </React.Fragment>
    );
  }
}

AdminUiFormAutocompleteUsersComponent.propTypes = {
  name: PropTypes.string.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  onRemoveUser: PropTypes.func,
  onChangeUser: PropTypes.func
};

AdminUiFormAutocompleteUsersComponent.defaultProps = {
  user: null,
  placeholder: '',
  onRemoveUser: () => {},
  onChangeUser: () => {}
};

export default AdminUiFormAutocompleteUsersComponent;
