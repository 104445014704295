import { MATCH } from 'constants/match';

export const matchStatus = { // eslint-disable-line import/prefer-default-export
  isWaitingPlayer: (status) => { return MATCH.STATUSES.WAITING_PLAYER === status; },
  isWaitingOpponent: (status) => { return MATCH.STATUSES.WAITING_OPPONENT === status; },
  isPending: (status) => { return MATCH.STATUSES.PENDING === status; },
  isUpcoming: (status) => { return MATCH.STATUSES.FUTURE === status; },
  isCheckIn: (status) => { return MATCH.STATUSES.CHECK_IN === status; },
  isCheckInBuffer: (status) => { return MATCH.STATUSES.CHECK_IN_BUFFER === status; },
  isServersBanning: (status) => { return MATCH.STATUSES.SERVERS_BANNING === status; },
  isMapsPickbanning: (status) => { return MATCH.STATUSES.MAPS_PICKBANNING === status; },
  isWaitingLobby: (status) => { return MATCH.STATUSES.WAITING_LOBBY === status; },
  isLive: (status) => { return MATCH.STATUSES.LIVE === status; },
  isWaiting: (status) => { return MATCH.STATUSES.WAITING === status; },
  isScoreEntered: (status) => { return MATCH.STATUSES.SCORE_ENTERED === status; },
  isScoreApproved: (status) => { return MATCH.STATUSES.SCORE_APPROVED === status; },
  isProtestCreated: (status) => { return MATCH.STATUSES.PROTEST_CREATED === status; },
  isProtestClosed: (status) => { return MATCH.STATUSES.PROTEST_CLOSED === status; },
  isTechScoreApproved: (status) => { return MATCH.STATUSES.TECH_SCORE_APPROVED === status; },
  isFinished: (status) => { return MATCH.STATUSES.FINISHED === status; }
};
