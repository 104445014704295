import React from 'react';
import * as PropTypes from 'prop-types';

import * as Form from 'admin/components/ui/form/main';

export default class RadioFromGroupReduxFormContainer extends React.Component {
  render() {
    const {
      input,
      label,
      required: requiredFromProps,
      meta: { touched, error },
      options,
      disabled
    } = this.props;

    return (
      <Form.Group
        labelStr={ label }
        error={ !!error && touched }
        errorMsgs={ error }
        required={ requiredFromProps }
      >
        <Form.Radio
          name="gender"
          state={ input.value }
          onChange={ (event) => { event.persist(); input.onChange(event.target.value); } }
          options={ options }
          disabled={ disabled }
        />
      </Form.Group>
    );
  }
}

RadioFromGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool
  }).isRequired
};

RadioFromGroupReduxFormContainer.defaultProps = {
  required: false
};
