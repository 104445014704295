const deleteEmptyFields = (object) => {
  const newObject = {};
  Object.keys(object).map((key) => {
    if (object[key] || object[key] === 0) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};

export default deleteEmptyFields;
