import React from 'react';
import PropTypes from 'prop-types';

const AdminUiListEmpty = ({ colSpan, text }) => (
  <tr>
    <td colSpan={ colSpan }>
      <p
        style={ { fontSize: '24px', marginTop: '10px' } }
        className="text-center"
      >
        {text}
      </p>
    </td>
  </tr>
);

AdminUiListEmpty.propTypes = {
  colSpan: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default AdminUiListEmpty;
