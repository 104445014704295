import React from 'react';
import * as PropTypes from 'prop-types';

import AdminUiBtnComponent from 'admin/components/ui/btn';
import AdminUiInputComponent from 'admin/components/ui/form/input';
import * as Form from 'admin/components/ui/form/main';

const GameIdInputReduxFormContainer = ({
  input,
  label,
  type,
  placeholder,
  required: requiredFromProps,
  meta: { touched, error },
  disabled,
  icon,
  hasVerificationError,
  onRefresh
}) => {
  if (hasVerificationError) {
    return (
      <Form.Group
        labelStr={ label }
        error={ !!error && touched }
        errorMsgs={ error }
        required={ requiredFromProps }
        icon={ icon }
      >
        <div className="row">
          <div className="col-sm-10">
            <AdminUiInputComponent { ...input } type={ type } placeholder={ placeholder } disabled={ disabled } />
          </div>
          <div className="col-sm-2">
            <AdminUiBtnComponent
              size="sm"
              mod="info"
              pull="right"
              onClick={ onRefresh }
            >
              <i className="fa fa-refresh" />
            </AdminUiBtnComponent>
          </div>
        </div>
      </Form.Group>
    );
  }

  return (
    <Form.Group
      labelStr={ label }
      error={ !!error && touched }
      errorMsgs={ error }
      required={ requiredFromProps }
      icon={ icon }
    >
      <AdminUiInputComponent { ...input } type={ type } placeholder={ placeholder } disabled={ disabled } />
    </Form.Group>
  );
};


GameIdInputReduxFormContainer.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  hasVerificationError: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.array,
    touched: PropTypes.bool
  }).isRequired,
  onRefresh: PropTypes.func
};

GameIdInputReduxFormContainer.defaultProps = {
  required: false,
  disabled: false,
  hasVerificationError: false,
  label: '',
  onRefresh: () => {}
};

export default GameIdInputReduxFormContainer;
