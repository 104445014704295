export const ITEMS_PER_PAGE = [10, 25, 50, 100, 200];
export const ADMIN_PER_PAGE_COOKIE = 'admin_per_page';
export const ADMIN_PER_PAGE_DEFAULT = 25;

export const ADMIN_PER_PAGE = 'ADMIN_PER_PAGE';

// sorting
export const SORTING_ASC = 'asc';
export const SORTING_DESC = 'desc';

export const COMMON_ERR = 'Something wrong !';
