import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AdminUsersFormContainer from 'admin/containers/users/form/user_form';

import * as editUserActions from 'actions/admin/users/edit';

class AdminUsersEditContainer extends React.Component {
  render() {
    const {
      countries,
      routes,
      user,
      actions,
      notification,
      isErrored
    } = this.props;
    return (
      <AdminUsersFormContainer
        notification={ notification }
        isErrored={ isErrored }
        countries={ countries }
        initialValues={ user }
        avatarSrc={ routes.prevAvatarUrl }
        actionsLogUrl={ routes.actionsLogUrl }
        activityLogsUrl={ routes.activityLogsUrl }
        handleSubmitForm={ actions.submitForm }
        handleSetAvatar={ actions.submitAvatar }
        handleCloseAlert={ actions.closeAlert }
      />
    );
  }
}

const mapStateToProps = state => ({
  locale: state.intl.locale,
  countries: state.adminUserEdit.countries,
  routes: state.adminUserEdit.routes,
  user: state.adminUserEdit.user,
  isErrored: state.adminUserEdit.formState.isErrored,
  notification: state.adminUserEdit.formState.notification
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(editUserActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersEditContainer);


AdminUsersEditContainer.propTypes = {
  notification: PropTypes.string,
  isErrored: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  routes: PropTypes.shape({
    prevAvatarUrl: PropTypes.string,
    newAvatarUrl: PropTypes.string,
    actionsLogUrl: PropTypes.string,
    activityLogsUrl: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    identity_providers: PropTypes.arrayOf(PropTypes.string),
    nick: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    country: PropTypes.string,
    uid: PropTypes.number,
    edit_auth_user_url: PropTypes.string
  }),
  actions: PropTypes.shape({
    submitForm: PropTypes.func,
    submitAvatar: PropTypes.func,
    closeAlert: PropTypes.func
  }).isRequired
};

AdminUsersEditContainer.defaultProps = {
  isErrored: false,
  notification: '',
  countries: [],
  routes: {},
  user: {}
};
