import PropTypes from 'prop-types';
import React from 'react';

class AdminUiPanelBodyComponent extends React.Component {
  static propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.node.isRequired
  }

  static defaultProps = {
    hidden: false
  }

  render() {
    return (
      <div>
        {
          !this.props.hidden &&
          <div className="panel-body">
            { this.props.children }
          </div>
        }
      </div>
    );
  }
}

export default AdminUiPanelBodyComponent;
