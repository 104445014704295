import React from 'react';

import AdminUiIconComponent from 'admin/components/ui/icon';

class AdminUiIconLoaderComponent extends React.Component {
  render() {
    return (
      <AdminUiIconComponent mod="pacman" spin />
    );
  }
}

export default AdminUiIconLoaderComponent;
