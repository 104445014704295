import { SubmissionError, initialize } from 'redux-form';

import * as users from 'constants/admin/users';
import fetchApi from 'admin/api/api';
import createUserFormData from 'admin/helpers/users/create_user_form_data';
import createAvatarFormData from 'admin/helpers/utils/create_avatar_form_data';
import deleteEmptyFields from 'admin/helpers/utils/delete_empty_fields';

export const showAlert = notification => ({ type: users.SHOW_ALERT_ADMIN_USERS_EDIT, payload: notification });
export const closeAlert = () => ({ type: users.CLOSE_ALERT_ADMIN_USERS_EDIT });

export const fetchDataStart = () => ({ type: users.START_FETCH_DATA_ADMIN_USERS_EDIT });
export const fetchDataOk = payload => ({ type: users.OK_FETCH_DATA_ADMIN_USERS_EDIT, payload });
export const fetchDataFail = errorMessage => ({ type: users.FAIL_FETCH_DATA_ADMIN_USERS_EDIT, payload: errorMessage });
export const fetchData = id => async (dispatch, getState) => {
  const { intl: { locale } } = getState();
  await dispatch(fetchDataStart());
  try {
    const data = await fetchApi(`/${locale}/users/${id}.json`, { method: 'GET' });
    await dispatch(fetchDataOk(data));
  } catch ({ errors, networkError }) {
    dispatch(fetchDataFail(errors, networkError || 'SomethingWrong'));
  }
};

export const submitFormStart = () => ({ type: users.START_SUBMIT_FORM_ADMIN_USERS_EDIT });
export const submitFormOk = () => ({ type: users.OK_SUBMIT_FORM_ADMIN_USERS_EDIT });
export const submitFormFail = () => ({ type: users.FAIL_SUBMIT_FORM_ADMIN_USERS_EDIT });
export const submitForm = user => async (dispatch, getState) => {
  const { adminUserEdit: { user: { id } } } = getState();
  dispatch(closeAlert());
  dispatch(submitFormStart());
  const { intl: { locale } } = getState();
  return fetchApi(`/${locale}/users/${id}.json`, { method: 'PUT', body: createUserFormData(user) })
    .then((data) => {
      dispatch(submitFormOk(data));
      dispatch(showAlert(data.notice));
      const updatedData = {
        ...data,
        country: data.country && typeof data.country === 'string' && data.country.toLowerCase() || null
      };
      delete updatedData.notice;
      dispatch(initialize('AdminUsersNewForm', deleteEmptyFields(updatedData)));
    }).catch(({ networkError, errors }) => {
      if (networkError || !errors) {
        dispatch(submitFormFail());
        dispatch(showAlert(networkError || 'Something wrong'));
      } else {
        dispatch(submitFormFail());
      }
      throw new SubmissionError({
        ...errors,
        _error: 'Submit failed!'
      });
    });
};

export const submitAvatarStart = () => ({ type: users.START_SUBMIT_AVATAR_ADMIN_USERS_EDIT });
export const submitAvatarOk = payload => ({ type: users.OK_SUBMIT_AVATAR_ADMIN_USERS_EDIT, payload });
export const submitAvatarFail = () => ({ type: users.FAIL_SUBMIT_AVATAR_ADMIN_USERS_EDIT });
export const submitAvatar = base64Avatar => async (dispatch, getState) => {
  dispatch(closeAlert());
  dispatch(submitAvatarStart());
  const { intl: { locale }, adminUserEdit: { user: { id } } } = getState();
  try {
    const data = await fetchApi(
      `/${locale}/users/${id}/avatar_upload.json`,
      { method: 'POST', body: createAvatarFormData({ avatarBase64: base64Avatar }) }
    );
    const { notice } = data;
    dispatch(submitAvatarOk(data));
    dispatch(showAlert(notice));
  } catch (err) {
    console.warn(err);
    const { networkError, errors } = err;
    dispatch(submitAvatarFail());
    dispatch(showAlert(networkError || errors || 'Something wrong'));
  }
};
