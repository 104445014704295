import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import qs from 'qs';

import AdminTeamsListComponent from 'admin/components/teams/list/list';

import * as adminTeamsListActions from 'actions/admin/teams/list';
import * as adminActionsImports from 'actions/admin';
import { filters } from 'constants/admin/teams';
import { SORTING_ASC, SORTING_DESC } from 'constants/admin';

class AdminTeamsListContainer extends Component {
  constructor(props) {
    super(props);
    const { actions } = props;
    this.debouncedFetchTeams = debounce(actions.fetchTeamsList, 500);
  }

  componentDidMount() {
    const { actions } = this.props;

    this.updateFilterByQueryString();
    actions.fetchTeamsList();
    actions.fetchDisciplines();
  }

  updateFilterByQueryString = () => {
    const { actions, adminActions } = this.props;
    const { location: { search } } = window;
    const queryParams = qs.parse(search.replace('?', ''));
    const {
      sort,
      filter,
      direction,
      admin_per_page: perPage,
      page
    } = queryParams;

    if (sort) {
      actions.updateSorting({ [sort]: direction });
    }
    if (filter) {
      Object.keys(filter).map((key) => {
        if (filter[key]) {
          actions.updateFilter({ [key]: filter[key] });
          if (key === filters.BY_DISCIPLINE) {
            actions.fetchRegions(filter[key]);
          }
        }
      });
    }
    if (perPage && Number(perPage)) {
      adminActions.setPerPageCall(Number(perPage));
    }
    if (page && Number(page) !== 1) {
      actions.changePage(Number(page));
    }
  };

  handlePageChange = (data) => {
    const { actions } = this.props;

    actions.changePage(data.selected + 1);
    actions.fetchTeamsList();
  };

  handleFilterChange = (event) => {
    const { actions, filter } = this.props;
    const { target: { name, value, tagName = '' } } = event;
    const newFilter = {
      ...filter,
      [name]: value || ''
    };

    if (name === filters.BY_DISCIPLINE) {
      newFilter.by_region = '';

      if (value) {
        actions.fetchRegions(value);
      } else {
        actions.fetchRegionsOk([]);
      }
    }

    actions.updateFilter(newFilter);
    actions.changePage(1);

    if (tagName.toUpperCase() === 'INPUT') {
      this.debouncedFetchTeams();
    } else {
      actions.fetchTeamsList();
    }
  };

  handleResetFilter = () => {
    const { actions } = this.props;
    actions.resetFilter();
    actions.fetchRegionsOk([]);
    actions.changePage(1);
    actions.fetchTeamsList();
  };

  handleSort = (sortName) => {
    const {
      actions, sort
    } = this.props;
    let sortValue = SORTING_ASC;

    if (Object.prototype.hasOwnProperty.call(sort, sortName)) {
      if (sort[sortName] === SORTING_ASC) {
        sortValue = SORTING_DESC;
      }
    }
    actions.updateSorting({ [sortName]: sortValue });
    actions.changePage(1);
    actions.fetchTeamsList();
  };

  resetSorting = () => {
    const { actions } = this.props;
    actions.resetSorting();
    actions.fetchTeamsList();
  };

  handleItemsPerPage = (itemPerPage) => {
    const { actions, adminActions } = this.props;
    adminActions.changePerPage(itemPerPage);
    actions.changePage(1);
    actions.fetchTeamsList();
  };

  render() {
    const {
      teams,
      filter,
      sort,
      pagination: {
        total,
        pages,
        page
      },
      perPage: adminPerPage,
      isLoadingTeams,
      disciplines,
      regionsByDiscipline,
      locale
    } = this.props;
    return (
      <AdminTeamsListComponent
        teams={ teams }
        total={ total }
        adminPerPage={ adminPerPage }
        isLoadingTeams={ isLoadingTeams }
        pages={ pages }
        page={ page }
        filter={ filter }
        sorting={ sort }
        disciplines={ disciplines }
        regionsByDiscipline={ regionsByDiscipline }
        handleSort={ this.handleSort }
        handleFilterChange={ this.handleFilterChange }
        resetFilter={ this.handleResetFilter }
        resetSorting={ this.resetSorting }
        handlePageChange={ this.handlePageChange }
        handleItemsPerPage={ this.handleItemsPerPage }
        locale={ locale }
      />
    );
  }
}

AdminTeamsListContainer.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  sort: PropTypes.objectOf(PropTypes.string).isRequired,
  pagination: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number
  }).isRequired,
  perPage: PropTypes.number.isRequired,
  isLoadingTeams: PropTypes.bool.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.object).isRequired,
  regionsByDiscipline: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.shape({
    fetchTeamsList: PropTypes.func.isRequired,
    fetchDisciplines: PropTypes.func.isRequired,
    fetchRegionsOk: PropTypes.func.isRequired,
    resetSorting: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired
  }).isRequired,
  adminActions: PropTypes.shape({
    setPerPageCall: PropTypes.func.isRequired
  }).isRequired,
  locale: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  ...state.adminTeamsList,
  ...state.admin,
  locale: state.intl.locale
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    adminTeamsListActions,
    dispatch
  ),
  adminActions: bindActionCreators(
    adminActionsImports,
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamsListContainer);
