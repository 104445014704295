import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class AdminUiFormGroupComponent extends React.Component {
  render() {
    const {
      required,
      error,
      children,
      labelStr,
      errorMsgs,
      hint,
      icon
    } = this.props;
    return (
      <div className={ classNames('form-group', { 'has-error': error }) }>
        { labelStr && (
        <p className="form-label">
          { required && (
          <span>
            <abbr title="Required">*</abbr>
&nbsp;
          </span>
          ) }
          {labelStr}
          {icon && ' '}
          {icon}
        </p>
        )}
        <div>
          { children }
        </div>
        { error
          && (
          <span className="help-block">
            {errorMsgs.join(', ')}
          </span>
          )
        }
        { !error && hint && <span className="help-block">{ hint }</span> }
      </div>
    );
  }
}

AdminUiFormGroupComponent.propTypes = {
  labelStr: PropTypes.string,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  errorMsgs: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.node,
  notice: PropTypes.any
};

AdminUiFormGroupComponent.defaultProps = {
  error: false,
  required: false,
  errorMsgs: [],
  labelStr: '',
  icon: null,
  notice: ''
};
