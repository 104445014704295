export const MATCH = { // eslint-disable-line import/prefer-default-export
  NAME: 'tms_match',
  STATUSES: {
    INITIAL: 'initial',
    WAITING_PLAYER: 'waiting_player',
    WAITING_OPPONENT: 'waiting_opponent',
    PENDING: 'pending',
    FUTURE: 'future',
    CHECK_IN: 'check_in',
    CHECK_IN_BUFFER: 'check_in_buffer',
    SERVERS_BANNING: 'servers_banning',
    MAPS_PICKBANNING: 'maps_pickbanning',
    WAITING_LOBBY: 'waiting_lobby',
    LIVE: 'live',
    WAITING: 'waiting',
    SCORE_ENTERED: 'score_entered',
    SCORE_CHANGED: 'score_changed',
    SCORE_APPROVED: 'score_approved',
    PROTEST_CREATED: 'protest_created',
    PROTEST_CLOSED: 'protest_closed',
    TECH_SCORE_APPROVED: 'tech_score_approved',
    FINISHED: 'finished',
    CLOSED: 'closed'
  }
};
