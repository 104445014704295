import React from 'react';
import PropTypes from 'prop-types';

import AdminStatusBadgeComponent from 'admin/components/status_badge';
import AdminUiIconComponent from 'admin/components/ui/icon';

import { matchStatus } from 'helpers/match';
import { i18n } from 'core/services/i18n';

export default class AdminTmsDashboardBattleContainer extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    started_at: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    call_referee_source_url: PropTypes.string,
    referee: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      name: PropTypes.string
    }),
    tournament_group: PropTypes.shape({
      index: PropTypes.number,
      label: PropTypes.string
    }),
    tournament_groups: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number,
      label: PropTypes.string
    })).isRequired,
    map: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }

  static defaultProps = {
    call_referee_source_url: null,
    referee: null,
    tournament_group: null,
    map: null
  }

  constructor() {
    super();

    this.state = {
      players: [],
      scores: [],
      isParticipantsTableVisible: false
    };
  }

  getBattleResults = () => {
    const { url } = this.props;
    return $.ajax({
      url,
      dataType: 'json',
      cache: false
    });
  }

  handleClickParticipants = () => {
    const { isParticipantsTableVisible } = this.state;

    if (isParticipantsTableVisible) {
      this.setState(prevState => ({
        isParticipantsTableVisible: !prevState.isParticipantsTableVisible
      }));
    } else {
      this.getBattleResults().done((result) => {
        this.setState(prevState => ({
          players: result.players,
          scores: result.scores,
          isParticipantsTableVisible: !prevState.isParticipantsTableVisible
        }));
      });
    }
  }

  render() {
    const {
      id,
      position,
      started_at,
      status,
      url,
      call_referee_source_url,
      tournament_group,
      tournament_groups,
      referee,
      map
    } = this.props;

    const {
      isParticipantsTableVisible,
      scores,
      players
    } = this.state;

    const groupLabel = tournament_group == null
      ? tournament_groups.map(group => group.label).join('+')
      : tournament_group.label;

    return (
      <React.Fragment>
        <tr>
          <td>{ id }</td>
          <td>#{ position }</td>
          <td>{ groupLabel }</td>
          <td dangerouslySetInnerHTML={ { __html: started_at } } />
          <td>{ map && map.name }</td>
          <td>
            <a href={ referee.url } target="_blank" rel="noopener noreferrer">
              { referee && referee.name }
            </a>
          </td>
          <td>
            <AdminStatusBadgeComponent status={ status }>
              { i18n(`tms.match_statuses.${status}`) }
            </AdminStatusBadgeComponent>
          </td>
          <td>
            {
              call_referee_source_url !== null && (
                <a href={ call_referee_source_url } target="_blank" rel="noopener noreferrer">
                  <AdminUiIconComponent mod="bell" />
                </a>
              )
            }
          </td>
          <td>
            <span
              className="btn btn-default pull-right"
              role="button"
              tabIndex={ 0 }
              onKeyPress={ this.handleClickParticipants }
              onClick={ this.handleClickParticipants }
            >
              { i18n('tms.tournament.players') }
            </span>
          </td>
          <td>
            <a href={ url } className="btn btn-default pull-right" target="_blank" rel="noopener noreferrer">
              { i18n('admin.tms.dashboard.matches.manage') }
            </a>
          </td>
        </tr>
        {
          isParticipantsTableVisible && (
            <tr>
              <td colSpan="7">
                <table className="table table-condensed table-bordered">
                  <thead>
                    <tr>
                      <th style={ { textAlign: 'center' } }>{ i18n('simple_form.labels.tms_battle_standing.position') }</th>
                      <th style={ { textAlign: 'center' } }>{ i18n('tms.tournament.players') }</th>
                      <th style={ { textAlign: 'center' } }>{ i18n('tms.tournament.standings.label_placement_pts') }</th>
                      <th style={ { textAlign: 'center' } }>{ i18n('tms.tournament.standings.label_kills') }</th>
                      <th style={ { textAlign: 'center' } }>{ i18n('tms.tournament.standings.label_kills_pts') }</th>
                      <th style={ { textAlign: 'center' } }>{ i18n('tms.tournament.standings.label_overall_points') }</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      matchStatus.isFinished(status) ? (
                        scores.map((score) => {
                          const player = players.find(item => item.id === score.data.playerId);
                          return (
                            <tr key={ score.id }>
                              <td>{ score.data.position }</td>
                              <td style={ { textAlign: 'left' } }>
                                {
                                  player && (
                                    <a href={ player.url } target="_blank" rel="noopener noreferrer">
                                      { player && player.name }
                                    </a>
                                  )
                                }
                              </td>
                              <td>{ score.data.positionPoints }</td>
                              <td>{ score.data.kills }</td>
                              <td>{ score.data.killsPoints }</td>
                              <td>{ score.data.positionPoints + score.data.killsPoints }</td>
                            </tr>
                          );
                        })
                      ) : (
                        players.map((player) => {
                          return (
                            <tr key={ player.id }>
                              <td> - </td>
                              <td style={ { textAlign: 'left' } }>
                                {
                                  player && (
                                    <a href={ player.url } target="_blank" rel="noopener noreferrer">
                                      { player && player.name }
                                    </a>
                                  )
                                }
                              </td>
                              <td> - </td>
                              <td> - </td>
                              <td> - </td>
                              <td> - </td>
                            </tr>
                          );
                        })
                      )
                    }
                  </tbody>
                </table>
              </td>
            </tr>
          )
        }
      </React.Fragment>
    );
  }
}
