export default function createUserFormData(user) {
  const formData = new FormData();
  Object.keys(user).map((key) => {
    switch (key) {
      case 'city':
        if (user.city && user.city.name && user.city.place_id) {
          formData.append('user[city][name]', user.city ? user.city.name : '');
          formData.append('user[city][place_id]', user.city ? user.city.place_id : '');
        }
        break;
      case 'roles':
        if (Array.isArray(user.roles)) {
          user.roles.map((rolesValue) => {
            formData.append('user[roles][]', rolesValue);
          });
        }
        break;
      case 'country':
        formData.append(`user[${key}]`, user[key] && typeof user[key] === 'string' ? user[key].toUpperCase() : '');
        break;
      default:
        formData.append(`user[${key}]`, user[key] || '');
    }
  });
  return formData;
}
