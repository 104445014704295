import React from 'react';
import * as PropTypes from 'prop-types';

import * as Form from 'admin/components/ui/form/main';

const DatePikerFormGroupReduxFormContainer = ({
  input,
  label,
  required: requiredFromProps,
  meta: { touched, error },
  placeholder,
  dateFormat,
  disabled
}) => (
  <Form.Group
    labelStr={ label }
    error={ !!error && touched }
    errorMsgs={ error }
    required={ requiredFromProps }
  >
    <Form.Datepicker
      { ...input }
      value={ input.value || null }
        // onBlur={ (value) => { input.onBlur(value === 'Invalid date' ? 'undefined' : value); } }
      inputValueFormat={ dateFormat }
      placeholder={ placeholder }
      onChange={ ({ value }) => { input.onChange(value); } }
        // onChangeRaw={ ({ target: { value } }) => { console.log('value ->', value); input.onChange(value); } }
      dateFormat={ dateFormat }
      disabled={ disabled }
      isClearable
    />
  </Form.Group>
);

DatePikerFormGroupReduxFormContainer.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool
  }).isRequired
};

DatePikerFormGroupReduxFormContainer.defaultProps = {
  placeholder: '',
  required: false
};

export default DatePikerFormGroupReduxFormContainer;
