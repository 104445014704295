export const required = errorMessage => value => (value ? null : [errorMessage]);

export const stringLength = (min, max, errorMessage) => value => (
  value && value.length > max || value && value.length < min
    ? [errorMessage]
    : null
);

export const email = errorMessage => (value) => {
  const reg = new RegExp('^([\\w.%+-]+)@([\\w-]+\\.)+([\\w]{2,})$', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};

export const skypeAccount = errorMessage => (value) => {
  const reg = new RegExp('^[a-z][a-z0-9\\.,\\-_]{5,31}$', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};


export const twitterLink = errorMessage => (value) => {
  const reg = new RegExp('http(?:s)?:\\/\\/(?:www\\.)?twitter\\.com\\/([a-zA-Z0-9_]+)', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};

export const vkLink = errorMessage => (value) => {
  const reg = new RegExp('^(https?:\\/\\/)?(www\\.)?vk\\.com\\/(\\w|\\d)+?\\/?$', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};

export const facebookLink = errorMessage => (value) => {
  const reg = new RegExp('(?:https?:\\/\\/)?(?:www\\.)?facebook\\.com\\/.(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[\\w\\-]*\\/)*([\\w\\-\\.]*)', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};

export const youtubeLink = errorMessage => (value) => {
  const reg = new RegExp('((http|https):\\/\\/|)(www\\.|)youtube\\.com\\/(channel\\/|user\\/)[a-zA-Z0-9\\-]{1,}', 'i');
  if (value) {
    if (!reg.test(value) || value.length > 255) {
      return [errorMessage];
    }
  }
  return null;
};

export const notSpecChar = errorMessage => (value) => {
  const reg = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/g;
  if (reg.test(value)) {
    return [errorMessage];
  }
  return null;
};


export const notSpecCharWithWhiteSpace = errorMessage => (value) => {
  const reg = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/g;
  if (reg.test(value)) {
    return [errorMessage];
  }
  return null;
};
