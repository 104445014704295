import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

class AdminUiBtnComponent extends React.Component {
  static defaultProps = {
    mod: 'default',
    size: null,
    pull: null,
    disabled: false,
    onClick: () => {},
    children: null,
    type: 'button',
    active: false
  }

  static propTypes = {
    mod: PropTypes.oneOf(['default', 'primary', 'info', 'success', 'warning', 'danger', 'link', 'icon']),
    size: PropTypes.oneOf(['lg', 'sm', 'xs']),
    pull: PropTypes.oneOf(['left', 'right']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.string,
    active: PropTypes.bool
  }

  render() {
    const className = classNames(
      'btn',
      `btn-${this.props.mod}`,
      { active: this.props.active },
      { [`btn-${this.props.size}`]: this.props.size },
      { [`pull-${this.props.pull}`]: this.props.pull }
    );

    return (
      <button
        type={ this.props.type }
        className={ className }
        onClick={ this.props.onClick }
        disabled={ this.props.disabled }
      >
        { this.props.children }
      </button>
    );
  }
}

export default AdminUiBtnComponent;
