import { createAction } from 'redux-act';

export const setPerPage = createAction('set admin per page value', data => data);
export const setPerPageCall = data => async (dispatch) => {
  await dispatch(setPerPage(data));
};

export const changePerPage = data => async (dispatch) => {
  // @TODO store to cookie here
  await dispatch(setPerPage(data));
};
