import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const STATUS_MAPPING = {
  initial: 'success',
  waiting_player: 'success',
  waiting_opponent: 'success',
  pending: 'success',
  future: 'success',
  live: 'danger',
  protest_created: 'danger'
};

class AdminStatusBadgeComponent extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    status: PropTypes.string
  }

  static defaultProps = {
    status: 'default'
  }

  render() {
    return (
      <span className={ classNames('label', `label-${STATUS_MAPPING[this.props.status] || 'default'}`) }>
        { this.props.children }
      </span>
    );
  }
}

export default AdminStatusBadgeComponent;
