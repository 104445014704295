import qs from 'qs';
import { cloneDeep } from 'lodash/lang';

export default function createQueryJsonApiString(queryApiJsonObj = {}) {
  const newObj = cloneDeep(queryApiJsonObj);
  if (newObj.fields) {
    Object.keys(newObj.fields).map((field) => {
      newObj.fields[field] = Array.isArray(newObj.fields[field]) && newObj.fields[field].join(',');
    });
  }
  if (newObj.include) {
    newObj.include = Array.isArray(newObj.include) && newObj.include.join(',');
  }
  return qs.stringify(newObj);
}
