import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'constants/index';

const makeWrapperDisabled = (disabled, wrapper) => {
  if (disabled) {
    wrapper.querySelector('input').setAttribute('style', 'background-color: #eee');
    wrapper.querySelector('.react-datepicker__input-container')
      .setAttribute('style', 'background-color: #eee');
  } else {
    wrapper.querySelector('.react-datepicker__input-container').removeAttribute('style');
    wrapper.querySelector('input').removeAttribute('style');
  }
};

export default class AdminUiFormDatepickerComponent extends React.Component {
  componentDidMount() {
    const { disabled } = this.props;
    makeWrapperDisabled(disabled, this.wrapper);
  }

  componentDidUpdate() {
    const { value, disabled } = this.props;
    if (!value) {
      if (this.datePicker && this.datePicker.state) this.datePicker.state.inputValue = null;
    }
    makeWrapperDisabled(disabled, this.wrapper);
  }

  getDatePicker = (node) => { this.datePicker = node; }

  getWrapper = (node) => { this.wrapper = node; }

  handleDatepickerChange = (date) => {
    const { name, onChange } = this.props;
    onChange({ name, value: date && moment(date).format() || null });
  }

  render() {
    const {
      isClearable, placeholder, name, dateFormat, value, disabled
    } = this.props;
    return (
      <div
        ref={ this.getWrapper }
      >
        <DatePicker
          ref={ this.getDatePicker }
          dropdownMode="scroll"
          selected={ value && moment(value) || null }
          onChange={ this.handleDatepickerChange }
          showYearDropdown
          disabledKeyboardNavigation
          isClearable={ disabled ? false : isClearable }
          minDate={ moment().subtract(120, 'years') }
          maxDate={ moment().subtract(10, 'years') }
          dateFormat={ dateFormat }
          name={ name }
          disabled={ disabled }
          placeholderText={ placeholder }
        />
      </div>
    );
  }
}

AdminUiFormDatepickerComponent.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool
};

AdminUiFormDatepickerComponent.defaultProps = {
  value: null,
  name: '',
  placeholder: '',
  isClearable: true,
  dateFormat: DATE_FORMAT,
  disabled: false
};
