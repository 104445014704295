import { DEFAULT_LOCALE } from 'constants/index';
import { TIME_DATE_FORMATS } from 'constants/date_time';

const DEFAULT_FORMAT = 'time_default';

function dateTimeFormat(format, locale) {
  const guaranteedFormat = TIME_DATE_FORMATS[format] === undefined ? DEFAULT_FORMAT : format;
  return TIME_DATE_FORMATS[guaranteedFormat][locale] || TIME_DATE_FORMATS[guaranteedFormat][DEFAULT_LOCALE];
}

export {
  dateTimeFormat
};
