import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import { ADMIN_AUTOSUGGEST_THEME } from 'admin/services/theme';
import fetchApi from 'admin/api/api';

const sliceSuggestions = (suggestions, maxlength = suggestions.length) => {
  if (Array.isArray(suggestions)) {
    return suggestions.slice(0, maxlength);
  }
  return [];
};

const getSuggestionValue = suggestion => suggestion.nick;

const renderSuggestion = suggestion => (
  <span>{`${suggestion.nick} ${suggestion.full_name}`}</span>
);


const fetchUsers = async (inputValue, locale, callback) => {
  try {
    const data = await fetchApi(`/${locale}/users.json?by_nick_or_fullname=${inputValue}`, { method: 'GET' });
    if (typeof callback === 'function') {
      callback(sliceSuggestions(data.result));
    }
  } catch ({ error, networkError }) {
    if (typeof callback === 'function') {
      callback([]);
    }
  }
};

const debouncedFetchUser = debounce(fetchUsers, 400);

class AdminUiFormAutocompleteUsersAsyncComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      inputValue: ''
    };
  }

  resetInput = () => {
    this.setState({
      inputValue: ''
    });
  }

  updateSuggestions = (suggestions) => {
    this.setState({ suggestions });
  }

  handleInputChange = (event, { newValue }) => {
    this.setState({ inputValue: newValue });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { locale } = this.props;
    const { inputValue } = this.state;
    if (value !== inputValue && (value && value.length >= 2)) {
      debouncedFetchUser(value, locale, this.updateSuggestions);
    }
  }

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
    this.updateSuggestions([]);
  }

  handleSelectValue = (event, { suggestion }) => {
    const { name, onSuggestionSelected } = this.props;
    this.updateSuggestions([]);
    onSuggestionSelected({ name, value: suggestion });
  }

  render() {
    const { placeholder } = this.props;
    const { suggestions, inputValue: value } = this.state;
    const inputProps = {
      placeholder,
      value,
      onChange: this.handleInputChange,
      onBlur: () => {}
    };
    return (
      <Autosuggest
        theme={ ADMIN_AUTOSUGGEST_THEME }
        suggestions={ suggestions }
        onSuggestionsFetchRequested={ this.handleSuggestionsFetchRequested }
        onSuggestionsClearRequested={ this.handleSuggestionsClearRequested }
        getSuggestionValue={ getSuggestionValue }
        onSuggestionSelected={ this.handleSelectValue }
        renderSuggestion={ renderSuggestion }
        inputProps={ inputProps }
      />
    );
  }
}

const mapStateToProps = state => ({ locale: state.intl.locale });

export default connect(mapStateToProps, null, null, { withRef: true })(AdminUiFormAutocompleteUsersAsyncComponent);

AdminUiFormAutocompleteUsersAsyncComponent.propTypes = {
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSuggestionSelected: PropTypes.func.isRequired
};

AdminUiFormAutocompleteUsersAsyncComponent.defaultProps = {
  placeholder: ''
};
