import PropTypes from 'prop-types';
import React from 'react';

import { i18n } from 'core/services/i18n';

import { filter } from 'lodash';

import * as Form from 'admin/components/ui/form/main';

class AdminTmsTournamentSettingsSharedComponent extends React.Component {
  static propTypes = {
    selectedDiscipline: PropTypes.number,
    selectedRegions: PropTypes.arrayOf(PropTypes.number),
    disciplines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    regions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      discipline_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    disciplineDisabled: PropTypes.bool
  }

  static defaultProps = {
    selectedDiscipline: null,
    selectedRegions: [],
    disciplineDisabled: false
  }

  constructor(props) {
    super(props);

    const selectedDiscipline = this.props.selectedDiscipline || this.props.disciplines[0].id

    this.state = {
      selectedDiscipline,
      selectedRegions: this.props.selectedRegions,
      regions: this.getRegions(selectedDiscipline)
    };

    this.getRegions = this.getRegions.bind(this);
    this.handleDisciplineSelect = this.handleDisciplineSelect.bind(this);
    this.handleRegionsSelect = this.handleRegionsSelect.bind(this);
    this.showDisciplineBlock = this.showDisciplineBlock.bind(this);
  }

  componentDidMount() {
    this.showDisciplineBlock();
  }

  getRegions(disciplineId) {
    return filter(this.props.regions, region => region.discipline_id === disciplineId);
  }

  getMmrStatus(disciplineId) {
    return _.find(this.props.disciplines, discipline => discipline.id === disciplineId).mmr;
  }

  handleDisciplineSelect(event) {
    const disciplineId = event.target.value;
    this.setState({
      selectedDiscipline: disciplineId,
      regions: this.getRegions(parseInt(disciplineId, 10))
    }, () => {
      this.showDisciplineBlock(disciplineId);
    });
  }

  handleRegionsSelect({ value }) {
    this.setState({ selectedRegions: value.split(',') });
  }

  showDisciplineBlock() {
    $('.settings-discipline').hide();
    $(`.settings-discipline-${this.state.selectedDiscipline}`).show();
    if(this.getMmrStatus(parseInt(this.state.selectedDiscipline, 10))) {
      $('.mmr-limits').show();
    } else {
      $('.mmr-limits').hide();
    }
  }

  render() {
    return (
      <div>
        <Form.Group labelStr={ i18n('simple_form.labels.tms_tournament.discipline_id') } required>
          <select
            className="form-control select required"
            name="tms_tournament[discipline_id]"
            id="tms_tournament_discipline_id"
            onChange={ this.handleDisciplineSelect }
            defaultValue={ this.state.selectedDiscipline }
            disabled={ this.props.disciplineDisabled }
          >
            {
              this.props.disciplines.map((discipline) => {
                return <option key={ discipline.id } value={ discipline.id }>{ discipline.name }</option>;
              })
            }
          </select>
        </Form.Group>

        <Form.Group labelStr={ i18n('simple_form.labels.tms_tournament.region_ids') }>
          {
            this.state.selectedRegions.map((regionId) => {
              return <input key={ `region${regionId}` } type="hidden" name="tms_tournament[region_ids][]" value={ regionId } />;
            })
          }

          <Form.AutocompleteMulti
            name="tms_tournament[region_ids][]"
            value={ this.state.selectedRegions }
            suggestions={ this.state.regions || [] }
            onSelect={ this.handleRegionsSelect }
            multi
          />
        </Form.Group>
      </div>
    );
  }
}

export default AdminTmsTournamentSettingsSharedComponent;
