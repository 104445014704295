import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { filter, slice } from 'lodash';
import { ADMIN_AUTOSUGGEST_THEME } from 'admin/services/theme';

export default class AdminUiFormAutocompleteMainComponent extends React.Component {
  static propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.string,
    onSuggestionSelected: PropTypes.func.isRequired
  }

  static defaultProps = {
    value: ''
  }

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      listValue: '',
      suggestions: []
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }

  onChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  onBlur() {
    if (this.state.value !== this.state.listValue) {
      this.setState({
        value: ''
      });
    }
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({ suggestions: this.getSuggestions(value) });
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  getSuggestions(value) {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    if (escapedValue === '') { return []; }
    const regex = new RegExp(`^${escapedValue}`, 'i');
    const filtered = filter(this.props.suggestions, item => regex.test(item));
    return slice(filtered, 0, 5);
  }

  getSuggestionValue(suggestion) {
    this.props.onSuggestionSelected(suggestion);
    this.setState({
      listValue: suggestion,
      value: suggestion
    });
    return suggestion;
  }

  renderSuggestion(suggestion) {
    return (<span>{ suggestion }</span>);
  }

  render() {
    const inputProps = {
      placeholder: '',
      value: this.state.value,
      onChange: this.onChange,
      onBlur: this.onBlur
    };

    return (
      <Autosuggest
        theme={ ADMIN_AUTOSUGGEST_THEME }
        suggestions={ this.state.suggestions }
        onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
        onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
        onSuggestionSelected={ this.callBackFunc }
        getSuggestionValue={ this.getSuggestionValue }
        renderSuggestion={ this.renderSuggestion }
        inputProps={ inputProps }
      />
    );
  }
}
