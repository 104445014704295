import React from 'react';
import * as PropTypes from 'prop-types';

import AdminUiInputComponent from 'admin/components/ui/form/input';
import * as Form from 'admin/components/ui/form/main';

const InputFormGroupReduxFormContainer = ({
  input,
  label,
  type,
  placeholder,
  required: requiredFromProps,
  meta: { touched, error },
  disabled,
  icon,
  extraComponent
}) => (
  <Form.Group
    labelStr={ label }
    error={ !!error && touched }
    errorMsgs={ error }
    required={ requiredFromProps }
    icon={ icon }
  >
    { extraComponent }
    <AdminUiInputComponent { ...input } type={ type } placeholder={ placeholder } disabled={ disabled } />
  </Form.Group>
);


InputFormGroupReduxFormContainer.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.array,
    touched: PropTypes.bool
  }).isRequired
};

InputFormGroupReduxFormContainer.defaultProps = {
  required: false,
  disabled: false,
  label: ''
};

export default InputFormGroupReduxFormContainer;
