import PropTypes from 'prop-types';
import React from 'react';

export default class AdminUiFormRadioComponent extends React.Component {
  render() {
    const {
      options, name, state, onChange, disabled
    } = this.props;
    return (
      <div className="radio-box">
        { options.map(option => (
          <div className="radio-inline" key={ option.value }>
            <label htmlFor={ option.value }>
              <input
                id={ option.value }
                name={ name }
                value={ option.value }
                onChange={ onChange }
                checked={ state === option.value }
                disabled={ disabled }
                type="radio"
              />
              { option.label }
            </label>
          </div>
        ))
        }
      </div>
    );
  }
}

AdminUiFormRadioComponent.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired
};
