import React from 'react';
import * as PropTypes from 'prop-types';
import Select from 'react-select';

import * as Form from 'admin/components/ui/form/main';

const SelectFormGroupReduxFormContainer = ({
  input,
  options,
  disabled,
  placeholder = '',
  label,
  meta: { touched, error },
  required,
  labelKey,
  valueKey,
  onInputChange,
  hint
}) => (
  <Form.Group
    labelStr={ label }
    error={ !!error && touched }
    errorMsgs={ error }
    required={ required }
    hint={ hint }
  >
    <Select
      { ...input }
      onBlur={ () => { input.onBlur(input.value); } }
      value={ input.value }
      valueKey={ valueKey }
      labelKey={ labelKey }
      options={ options }
      disabled={ disabled }
      placeholder={ placeholder }
      onInputChange={ onInputChange }
      escapeClearsValue={ false }
      autoBlur
    />
  </Form.Group>
);

SelectFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    touched: PropTypes.bool
  }).isRequired
};

SelectFormGroupReduxFormContainer.defaultProps = {
  placeholder: '',
  required: false,
  options: null
};

export default SelectFormGroupReduxFormContainer;
