import PropTypes from 'prop-types';
import React from 'react';

import { i18n } from 'core/services/i18n';
import AdminUiIconComponent from 'admin/components/ui/icon';

class AdminUiListLinkEditComponent extends React.Component {
  static defaultProps = {
    url: '#'
  }

  static propTypes = {
    url: PropTypes.string
  }

  render() {
    return (
      <a
        className="btn btn-sm btn-info"
        title={ i18n('admin.common.edit.title') }
        href={ this.props.url }
      >
        <AdminUiIconComponent mod="cog" adminLte />
      </a>
    );
  }
}

export default AdminUiListLinkEditComponent;
