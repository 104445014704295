import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { ADMIN_AUTOSUGGEST_THEME } from 'admin/services/theme';

const renderSuggestion = suggestion => (
  <span>
    {suggestion.structured_formatting.main_text}
    {' '}
    <small style={ { color: 'grey' } }>
      {suggestion.structured_formatting.secondary_text}
    </small>
  </span>);

const getCountryCodeFromCityDetails = ({ address_components: addressComponents }) => (
  addressComponents.reduce(((response, item) => {
    if (item.types && item.types.includes('country')) {
      return item.short_name;
    }
    return response;
  }), null)
);

export default class AdminUiFormAutocompleteCitiesComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: []
    };
  }

  static getDerivedStateFromProps({ city }, state) {
    if ((city && city.id) !== (state.selectedCity && state.selectedCity.id)) {
      return {
        inputValue: city && city.name || '', // eslint-disable-line
        selectedCity: city
      };
    }
    return null;
  }

  componentDidMount() {
    this.googleAutocompleteService = new google.maps.places.AutocompleteService();
    this.googlePlaceService = new google.maps.places.PlacesService(this.domElem);
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }


  onChange = (event, { newValue }) => {
    const { country, onChange, onCityRemoved } = this.props;
    if (newValue === null || newValue === '') {
      this.setState({
        inputValue: ''
      });
      if (newValue === '') {
        onChange(null);
        onCityRemoved(null);
      }
    } else {
      this.setState({
        inputValue: newValue

      });
      this.googleAutocompleteService.getPlacePredictions({
        componentRestrictions: { country: country === 'EU' || country === 'eu' ? null : country },
        types: ['(cities)'],
        input: newValue,
        sessionToken: this.sessionToken
      }, this.getPlacePredictions);
    }
  };

  onBlur = () => {
    const { selectedCity } = this.state;
    if (!selectedCity) {
      this.setState({
        inputValue: ''
      });
    } else {
      this.setState(state => ({
        inputValue: state.selectedCity && state.selectedCity.name
      }));
    }
  };

  getPlacePredictions = (predictions, status) => {
    if (status !== 'OK') { return; }
    this.setState({ suggestions: predictions });
  };

  onSuggestionsFetchRequested = () => {
    this.setState(state => ({ suggestions: state.suggestions }));
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { onChange, onCitySelected } = this.props;


    this.googlePlaceService.getDetails({
      address_components: ['address_components'],
      placeId: suggestion.place_id,
      sessionToken: this.sessionToken
    }, (countryDetails) => {
      const value = {
        id: suggestion.id,
        name: suggestion.structured_formatting.main_text,
        place_id: suggestion.place_id,
        country_code: getCountryCodeFromCityDetails(countryDetails)
      };
      this.setState({
        inputValue: value.name,
        selectedCity: value
      });
      onChange(value);
      onCitySelected(value);
    });
  };

  getSuggestionValue = suggestion => suggestion && suggestion.structured_formatting.main_text || '';

  getRef = (node) => { this.domElem = node; }

  render() {
    const { suggestions, inputValue: value } = this.state;
    const { placeholder } = this.props;
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur
    };

    return (
      <div>
        <Autosuggest
          theme={ ADMIN_AUTOSUGGEST_THEME }
          suggestions={ suggestions }
          onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
          onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
          onSuggestionSelected={ this.onSuggestionSelected }
          getSuggestionValue={ this.getSuggestionValue }
          renderSuggestion={ renderSuggestion }
          focusInputOnSuggestionClick={ false }
          inputProps={ inputProps }
        />
        <span ref={ this.getRef } />
      </div>
    );
  }
}

AdminUiFormAutocompleteCitiesComponent.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    place_id: PropTypes.string
  }),
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onCitySelected: PropTypes.func,
  onCityRemoved: PropTypes.func
};

AdminUiFormAutocompleteCitiesComponent.defaultProps = {
  placeholder: '',
  city: null,
  country: '',
  onCitySelected: () => {},
  onCityRemoved: () => {}
};
