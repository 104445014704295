import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Select from 'react-select';

import { countries } from 'admin/helpers/form/countries';

export const normalizeInputValue = (value, resetToDefaultValue, defaultValue) => {
  if (!resetToDefaultValue) {
    return value && typeof value === 'string' ? value.toLowerCase() : value;
  } if (!value) {
    return defaultValue;
  }
  return value && typeof value === 'string' ? value.toLowerCase() : value;
};

class AdminUiFormSelectCountryComponent extends Component {
  componentDidMount() {
    const {
      resetToDefaultValue, value, defaultValue, onChange
    } = this.props;
    if (resetToDefaultValue && !value) {
      onChange(defaultValue);
    }
  }

  handleChange = (value) => {
    const {
      onClearCountry, onChangeCountry, defaultValue, onChange, onlyCode, resetToDefaultValue
    } = this.props;
    if (resetToDefaultValue) {
      if (onlyCode) {
        if (!value || (value && value.key === defaultValue)) {
          onClearCountry(defaultValue);
        }
        onChange(value && (value.key || defaultValue));
        onChangeCountry(value && (value.key || defaultValue));
      } else {
        if (!value) onClearCountry(defaultValue);
        onChange(value || defaultValue);
        onChangeCountry(value || defaultValue);
      }
    } else if (onlyCode) {
      if (!value) onClearCountry(null);
      onChange(value && value.key);
      onChangeCountry(value && value.key);
    } else {
      if (!value) onClearCountry(null);
      onChange(value);
      onChangeCountry(value);
    }
  }

  render() {
    const {
      disabled,
      placeholder,
      labelKey,
      valueKey,
      resetToDefaultValue,
      defaultValue,
      value,
      onBlur
    } = this.props;
    return (
      <Select
        onChange={ this.handleChange }
        onBlur={ onBlur }
        value={ normalizeInputValue(value, resetToDefaultValue, defaultValue) }
        labelKey={ labelKey }
        valueKey={ valueKey }
        options={ countries }
        placeholder={ placeholder }
        disabled={ disabled }
        autoBlur
      />
    );
  }
}

AdminUiFormSelectCountryComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  resetToDefaultValue: PropTypes.bool,
  onlyCode: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClearCountry: PropTypes.func,
  onChangeCountry: PropTypes.func
};

AdminUiFormSelectCountryComponent.defaultProps = {
  value: null,
  placeholder: '',
  resetToDefaultValue: true,
  onlyCode: true,
  defaultValue: 'eu',
  labelKey: 'value',
  valueKey: 'key',
  disabled: false,
  onBlur: () => {},
  onChange: () => {},
  onClearCountry: () => {},
  onChangeCountry: () => {}
};

export default AdminUiFormSelectCountryComponent;
