import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import * as Form from 'admin/components/ui/form/main';
import AdminUiFormSelectCountryComponent from 'admin/components/ui/form/select/countries';

class SelectCountryFormGroupReduxFormContainer extends Component {
  render() {
    const {
      input,
      disabled,
      placeholder,
      label,
      meta: { touched, error },
      required,
      onInputChange,
      resetToDefaultValue,
      defaultValue,
      onChangeCountry,
      onClearCountry
    } = this.props;
    return (
      <Form.Group
        labelStr={ label }
        error={ !!error && touched }
        errorMsgs={ error }
        required={ required }
      >
        <AdminUiFormSelectCountryComponent
          { ...input }
          onBlur={ () => {
            input.onBlur(input.value);
          } }
          disabled={ disabled }
          placeholder={ placeholder }
          onInputChange={ onInputChange }
          defaultValue={ defaultValue }
          resetToDefaultValue={ resetToDefaultValue }
          onChangeCountry={ onChangeCountry }
          onClearCountry={ onClearCountry }
        />
      </Form.Group>
    );
  }
}

SelectCountryFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    touched: PropTypes.bool
  }).isRequired,
  resetToDefaultValue: PropTypes.bool,
  onlyCode: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onClearCountry: PropTypes.func,
  onChangeCountry: PropTypes.func
};

SelectCountryFormGroupReduxFormContainer.defaultProps = {
  placeholder: '',
  required: false,
  options: null,
  resetToDefaultValue: true,
  onlyCode: true,
  defaultValue: 'eu',
  labelKey: 'value',
  valueKey: 'key',
  onClearCountry: () => {},
  onChangeCountry: () => {}
};

export default SelectCountryFormGroupReduxFormContainer;
