import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AdminUIUserCellComponent extends Component {
  render() {
    const {
      url, logo, nick, fullName
    } = this.props;
    return (
      <a
        href={ url }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={ { width: 46 } }
          alt="logo"
          src={ logo }
        />
        <div className="caption text-center">
          <h5 style={ { marginBottom: 0 } }>
            {nick}
            <br />
            <small>
              {fullName}
            </small>
          </h5>
        </div>
      </a>
    );
  }
}

AdminUIUserCellComponent.propTypes = {
  logo: PropTypes.string,
  url: PropTypes.string.isRequired,
  nick: PropTypes.string,
  fullName: PropTypes.string
};

AdminUIUserCellComponent.defaultProps = {
  nick: '',
  logo: '#',
  fullName: ''
};

export default AdminUIUserCellComponent;
