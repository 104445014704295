import React from 'react';
import PropTypes from 'prop-types';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminTmsDashboardTournamentContainer from './tournament';

export default class AdminTmsDashboardMyTournamentsContainer extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      status: PropTypes.string,
      discipline: PropTypes.string,
      onlyMy: PropTypes.bool
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = { isFetching: true, tournaments: [], expanded: {} };

    this.getTournaments = this.getTournaments.bind(this);
    this.collapseTournament = this.collapseTournament.bind(this);
    this.expandTournament = this.expandTournament.bind(this);
    this.tournamentsUrl = this.tournamentsUrl.bind(this);
    this.updateList = this.updateList.bind(this);
  }

  componentDidMount() {
    const { params } = this.props;
    this.updateList(params);
  }

  componentWillReceiveProps(nextProps) {
    this.updateList(nextProps.params);
  }

  getTournaments(params) {
    return $.ajax({
      url: this.tournamentsUrl(params),
      dataType: 'json',
      cache: false
    });
  }

  collapseTournament(id) {
    const { expanded } = this.state;
    delete expanded[id];
  }

  expandTournament(id, tab) {
    const { expanded } = this.state;
    expanded[id] = tab;
  }

  tournamentsUrl(params) {
    const { url } = this.props;
    let query = '?';
    if (params.onlyMy) { query += 'only_my=true&'; }
    if (params.status !== 'all') { query += `with_status=${params.status}&`; }
    if (params.discipline !== 'all') { query += `discipline_id=${params.discipline}`; }
    return url + query;
  }

  updateList(params) {
    this.setState(
      { isFetching: true, tournaments: [] },
      () => {
        this.getTournaments(params).done((result) => {
          this.setState({
            isFetching: false,
            tournaments: result
          });
        });
      }
    );
  }

  render() {
    const {
      isFetching,
      expanded,
      tournaments
    } = this.state;

    if (isFetching) { return <AdminUiIconComponent spin />; }
    if (tournaments.length === 0) { return (<div>No tournaments</div>); }

    return (
      <div className="table-responsive">
        <div className="panel-group block panel-table">
          {
            tournaments && tournaments.map((tournament) => {
              return (
                <AdminTmsDashboardTournamentContainer
                  key={ tournament.id }
                  onExpand={ this.expandTournament }
                  onCollapse={ this.collapseTournament }
                  expandedTab={ expanded[tournament.id] }
                  { ...tournament }
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}
