import PropTypes from 'prop-types';
import React from 'react';

import AdminUiBtnComponent from 'admin/components/ui/btn';

import { ITEMS_PER_PAGE } from 'constants/admin.js';

class AdminUiPaginationPerPageComponent extends React.Component {
  static propTypes = {
    perPage: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  render() {
    return (
      <div>
        { ITEMS_PER_PAGE.map(item => (
          <AdminUiBtnComponent
            key={ `paginate-per-${item}` }
            size="xs"
            active={ this.props.perPage === item }
            mod={ this.props.perPage === item ? 'primary' : 'default' }
            onClick={ () => { this.props.handleChange(item); } }
          >
            { item }
          </AdminUiBtnComponent>
          ))
        }
      </div>
    );
  }
}

export default AdminUiPaginationPerPageComponent;
