import React from 'react';

import * as Form from 'admin/components/ui/form/main';
import * as PropTypes from 'prop-types';
import AdminUiFormAutocompleteUsersAsyncComponent from 'admin/components/ui/form/autocomplete/users/users';

class AutocompleteUserReduxFormContainer extends React.Component {
  handleChangeUser = async (value = null) => {
    const { input, onChangeUser } = this.props;
    await input.onChange(value);
    onChangeUser(value);
  }

  render() {
    const {
      input,
      label,
      placeholder,
      required: requiredFromProps,
      meta: { touched, error },
      hint
    } = this.props;

    return (
      <React.Fragment>
        <Form.Group
          labelStr={ label }
          error={ !!error && touched }
          errorMsgs={ error }
          required={ requiredFromProps }
          hint={ hint }
        >
          <AdminUiFormAutocompleteUsersAsyncComponent
            user={ input.value }
            name={ input.name }
            placeholder={ placeholder }
            onChangeUser={ this.handleChangeUser }
          />
        </Form.Group>
      </React.Fragment>
    );
  }
}

AutocompleteUserReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool
  }).isRequired,
  hint: PropTypes.any,
  onChangeUser: PropTypes.func,
  onRemoveUser: PropTypes.func
};

AutocompleteUserReduxFormContainer.defaultProps = {
  hint: '',
  placeholder: '',
  required: false,
  onChangeUser: () => {},
  onRemoveUser: () => {}
};

export default AutocompleteUserReduxFormContainer;
