import PropTypes from 'prop-types';
import React from 'react';
import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs';

import { i18n } from 'core/services/i18n';

import AdminUiBtnComponent from '../../../components/ui/btn';
import AdminUiIconComponent from '../../../components/ui/icon';
// import AdminUiPageHeaderComponent from '../../../components/ui/page_header';

import AdminTmsDashboardTournamentsContainer from './tournaments';

const TABS = ['myTournaments', 'allTournaments'];
const STATUSES = ['future', 'registration', 'confirmation', 'schedule', 'live', 'finished'];

class AdminTmsDashboard extends React.Component {
  static propTypes = {
    tournamentsUrl: PropTypes.string.isRequired,
    activeDiscipline: PropTypes.string,
    activeStatus: PropTypes.oneOf(STATUSES),
    activeTab: PropTypes.oneOf(TABS),
    disciplines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired
  };

  static defaultProps = {
    activeDiscipline: 'all',
    activeStatus: 'live',
    activeTab: 'myTournaments'
  };

  constructor(props) {
    super(props);

    this.state = {
      activeDiscipline: this.props.activeDiscipline,
      activeTab: this.props.activeTab,
      activeStatus: this.props.activeStatus,
      activeTabIndex: 0
    };

    this.onTabSelectHandler = this.onTabSelectHandler.bind(this);
    this.onDisciplineChange = this.onDisciplineChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.randomize = this.randomize.bind(this);
  }

  onTabSelectHandler(index) {
    this.setState({ activeTab: TABS[index], activeTabIndex: index });
  }

  onDisciplineChange(e) {
    this.setState({ activeDiscipline: e.target.value });
  }

  onStatusChange(e) {
    this.setState({ activeStatus: e.target.value });
  }

  isActiveTab(tab) {
    return tab === this.state.activeTab;
  }

  randomize() {
    this.setState({ random: Math.random() });
  }

  render() {
    return (
      <div>
        <Tabs className="demo-list" selectedIndex={ this.state.activeTabIndex }>
          <TabList>
            <Tab>
              <AdminUiBtnComponent
                mod={ this.isActiveTab('myTournaments') ? 'info' : 'default' }
                onClick={ () => {
                  this.onTabSelectHandler(0);
                } }
              >
                { i18n('admin.tms.dashboard.my_tournaments') }
              </AdminUiBtnComponent>
            </Tab>
            <Tab>
              <AdminUiBtnComponent
                mod={ this.isActiveTab('allTournaments') ? 'info' : 'default' }
                onClick={ () => {
                  this.onTabSelectHandler(1);
                } }
              >
                { i18n('admin.tms.dashboard.all_tournaments') }
              </AdminUiBtnComponent>
            </Tab>
            <Tab>
              <select
                className="form-control input-md"
                defaultValue={ this.state.activeStatus }
                onChange={ this.onStatusChange }
              >
                <option key="all" value="all">{ i18n('admin.tms.dashboard.all_statuses') }</option>
                {
                  STATUSES.map((status) => {
                    return (
                      <option key={ status } value={ status }>{ i18n(`tms.tournament_statuses.${status}`) }</option>
                    );
                  })
                }
              </select>
            </Tab>
            <Tab>
              <select
                className="form-control input-md"
                defaultValue={ this.state.activeDiscipline }
                onChange={ this.onDisciplineChange }
              >
                <option key="all" value="all">{ i18n('admin.tms.dashboard.all_disciplines') }</option>
                {
                  this.props.disciplines.map((discipline) => {
                    return (
                      <option key={ discipline.id } value={ discipline.id }>
                        { discipline.name }
                      </option>
                    );
                  })
                }
              </select>
            </Tab>
            <Tab>
              <AdminUiBtnComponent onClick={ this.randomize }>
                { i18n('admin.tms.dashboard.refresh') }
                <AdminUiIconComponent mod="loop4" />
              </AdminUiBtnComponent>
            </Tab>
          </TabList>
          <TabPanel>
            <AdminTmsDashboardTournamentsContainer
              url={ this.props.tournamentsUrl }
              params={ {
                status: this.state.activeStatus,
                discipline: this.state.activeDiscipline,
                onlyMy: true,
                random: this.state.random
              } }
            />
          </TabPanel>
          <TabPanel>
            <AdminTmsDashboardTournamentsContainer
              url={ this.props.tournamentsUrl }
              params={ { status: this.state.activeStatus, discipline: this.state.activeDiscipline, onlyMy: false } }
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default AdminTmsDashboard;
