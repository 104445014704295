import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminUiIconLoaderComponent from 'admin/components/ui/icon_loader';

class AdminTmsTournamentBattleSeedingPlayerContainer extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    seedingPlayerId: PropTypes.number,
    seedingPlayers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    seedingPlayerId: null
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: null,
      errors: {},
      index: props.index,
      seedingPlayerId: props.seedingPlayerId,
      url: props.url
    };
  }

  getRequestParams = (fieldName, fieldValue, previousSeedingPlayerId) => {
    const data = {
      tms_battle_seeding_player: {
        [fieldName]: fieldValue
      }
    };

    // CREATE
    if (!previousSeedingPlayerId && fieldName === 'seeding_player_id') {
      return { method: 'POST', data };
    }

    // DELETE
    if (previousSeedingPlayerId && fieldName === 'seeding_player_id' && !fieldValue) {
      return { method: 'DELETE', data };
    }

    // UPDATE
    return { method: 'PUT', data };
  };

  handleChange = (e) => {
    const { url, seedingPlayerId } = this.state;
    const { onChange } = this.props;
    const { name: fieldName, value: fieldValue } = e.target;

    this.setState(
      { updating: true, errors: {} },
      () => {
        const requestParams = this.getRequestParams(fieldName, fieldValue, seedingPlayerId);

        $.ajax({
          url,
          type: requestParams.method,
          dataType: 'json',
          data: requestParams.data,
          success: (data) => {
            // после удаления нам надо обнулить seedingPlayerId, а он не приходит в ответе от сервера. поэтому надо явно
            this.setState({ updating: false, seedingPlayerId: data.seedingPlayerId, ...data });
            onChange(data);
            this.checkFinishBtnStatus();
          },
          error: (response) => {
            const data = response.responseJSON;
            this.setState({
              updating: null,
              errors: {
                seedingPlayerId: data.seeding_player_id
              }
            });
          }
        });
      }
    );
  };

  render() {
    const { seedingPlayers } = this.props;
    const {
      index,
      seedingPlayerId,
      updating,
      errors
    } = this.state;

    return (
      <div className="row">
        <label className={ classnames('form-label', { 'has-error': errors.seedingPlayerId !== undefined }) }>
          <p className="col-sm-1">
            #
            { index + 1 }
          </p>
          <div className="col-sm-9">
            <select
              className="form-control"
              name="seeding_player_id"
              defaultValue={ seedingPlayerId }
              onChange={ this.handleChange }
            >
              <option key="empty" />
              { seedingPlayers.map(player => <option key={ player.id } value={ player.id }>{ player.name }</option>) }
            </select>
          </div>
          <div className="col-sm-1">
            { updating === true && <AdminUiIconLoaderComponent /> }
            { updating === false && <AdminUiIconComponent mod="checkmark" className="text-success" /> }
          </div>
        </label>
      </div>


    );
  }
}

export default AdminTmsTournamentBattleSeedingPlayerContainer;
