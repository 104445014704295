import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class AdminUiBoxComponent extends React.Component {
  static propTypes = {
    titleStr: PropTypes.string,
    modStr: PropTypes.string,
    children: PropTypes.node.isRequired,
    navNode: PropTypes.node
  }

  static defaultProps = {
    titleStr: null,
    modStr: null
  }

  render() {
    return (
      <div className={ classNames('box', { [`box-${this.props.modStr}`]: this.props.modStr }) }>
        {
          this.props.titleStr &&
          <div className="box-header with-border">
            <h3 className="box-title">{ this.props.titleStr }</h3>
            { this.props.navNode }
          </div>
        }
        { this.props.children }
      </div>
    );
  }
}
