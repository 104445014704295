import PropTypes from 'prop-types';
import React from 'react';

class AdminUiDisciplineIconComponent extends React.Component {
  static propTypes = {
    alias: PropTypes.oneOf([
      'brawlstars3x3', 'clashroyale1x1', 'csgo', 'dota2', 'dota2_1x1',
      'fortnite2x2', 'freefire1x1', 'freefire4x4', 'hots', 'hs',
      'kof', 'lol', 'mlbb', 'ow', 'paladins', 'pb', 'pes1x1',
      'pubg', 'pubg2x2', 'pubgmobile1x1', 'pubg4x4mobile',
      'sc2', 'tol', 'valorant5x5', 'vg5x5', 'wot', 'wot7x7', 'wow', 'naraka_bladepoint3x3', 'crossfire4x4',
      'honor_of_kings5x5', 'summoners_war'
    ]).isRequired
  }

  render() {
    const { alias } = this.props;
    const className = `discipline ${alias}`;

    return (
      <span className={ className } />
    );
  }
}

export default AdminUiDisciplineIconComponent;
