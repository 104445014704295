/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'starladder' %> to the appropriate
// layout file, like app/views/layouts/starladder.html.haml

import ReactOnRails from 'react-on-rails';

import AdminAvatarCropperComponent from 'admin/components/ui/avatar_cropper';
import AdminLinkableContainer from 'admin/containers/linkable';

import AdminUsersEditContainer from 'admin/containers/users/edit';
import AdminUsersNewContainer from 'admin/containers/users/new';
import AdminUsersListContainer from 'admin/containers/users/list';
import AdminTeamsListContainer from 'admin/containers/teams/list';

import AdminTmsDashboard from 'admin/containers/tms/dashboard';
import AdminTmsTournamentSettingsSharedComponent from 'admin/components/tms/tournament_settings/shared';
import AdminTmsTournamentBattleResultsContainer from 'admin/containers/tms/tournament/battle_results';
import AdminTmsTournamentBattleSeedingPlayersContainer from 'admin/containers/tms/tournament/battle_seeding_players';
import AdminTmsTournamentBattleStandingsContainer from 'admin/containers/tms/tournament/battle_standings';

// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  AdminAvatarCropperComponent,
  AdminLinkableContainer,
  AdminTeamsListContainer,
  AdminUsersListContainer,
  AdminUsersNewContainer,
  AdminUsersEditContainer,
  AdminTmsDashboard,
  AdminTmsTournamentSettingsSharedComponent,
  AdminTmsTournamentBattleResultsContainer,
  AdminTmsTournamentBattleSeedingPlayersContainer,
  AdminTmsTournamentBattleStandingsContainer
});
