import PropTypes from 'prop-types';
import React from 'react';

import ReactPaginate from 'react-paginate';

class AdminUiPaginationPagesComponent extends React.Component {
  static propTypes = {
    page: PropTypes.number.isRequired, // starting from 1
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired // to handle page select, starting from 1
  }

  handlePageChange = ({ selected }) => {
    this.props.handleChange(selected + 1);
  }

  render() {
    const {
      page, perPage, total
    } = this.props;
    return (
      <ReactPaginate
        pageCount={ total / perPage }
        pageRangeDisplayed={ 5 }
        marginPagesDisplayed={ 1 }
        // initialPage={ page - 1 }
        forcePage={ page - 1 }
        containerClassName="pagination pagination-sm no-margin pull-right"
        breakClassName="disabled"
        activeClassName="active"
        previousLabel="«"
        nextLabel="»"
        breakLabel={ <span>…</span> }
        onPageChange={ this.handlePageChange }
      />
    );
  }
}

export default AdminUiPaginationPagesComponent;
