import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminUiIconLoaderComponent from 'admin/components/ui/icon_loader';

class AdminTmsTournamentBattleStandingContainer extends React.Component {
  static propTypes = {
    showGroup: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    player: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    tournament_group: PropTypes.shape({
      index: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    }),
    battlesCount: PropTypes.number.isRequired,
    qualified: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    positions: PropTypes.number.isRequired,
    firstPositions: PropTypes.number.isRequired,
    kills: PropTypes.number.isRequired,
    positionPoints: PropTypes.number.isRequired,
    killsPoints: PropTypes.number.isRequired,
    bonusPoints: PropTypes.number.isRequired,
    money: PropTypes.number.isRequired,
    correctionPoints: PropTypes.number.isRequired,
    totalPoints: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      updating: null,
      errors: [],
      player: this.props.player,
      tournamentGroup: this.props.tournamentGroup,
      battlesCount: this.props.battlesCount,
      qualified: this.props.qualified,
      position: this.props.position,
      positions: this.props.positions,
      firstPositions: this.props.firstPositions,
      kills: this.props.kills,
      positionPoints: this.props.positionPoints,
      killsPoints: this.props.killsPoints,
      bonusPoints: this.props.bonusPoints,
      money: this.props.money,
      correctionPoints: this.props.correctionPoints,
      totalPoints: this.props.totalPoints
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState(
      { [e.target.name]: e.target.value },
      () => {
        this.setState({ updating: true, errors: [] });
        $.ajax({
          url: this.props.url,
          type: 'PUT',
          dataType: 'json',
          data: { tms_tournament_battle_standing: { correction_points: this.state.correctionPoints } },
          success: (data) => {
            this.setState({ updating: false });
            this.setState(data.data);
          },
          error: (response) => {
            const data = response.responseJSON;
            this.setState({
              updating: null,
              errors: {
                correctionPoints: data.errors.correction_points
              }
            });
          }
        });
      }
    );
  }

  render() {
    const { showGroup } = this.props;
    const { tournamentGroup } = this.state;

    return (
      <tr>
        <td>
          { this.state.position }
          {
            this.state.qualified === true
            && <AdminUiIconComponent mod="star" className="text-success" />
          }
        </td>
        { showGroup && <td>{ tournamentGroup.label }</td> }
        <td>
          <a href={ this.state.player.url }>{ this.state.player.name }</a>
        </td>
        <td>{ this.state.battlesCount }</td>
        <td>{ this.state.positions }</td>
        <td>{ this.state.firstPositions }</td>
        <td>{ this.state.kills }</td>
        <td>{ this.state.positionPoints }</td>
        <td>{ this.state.killsPoints }</td>
        <td>{ this.state.bonusPoints }</td>
        <td>{ this.state.money }</td>
        <td className={ classnames({ 'has-error': this.state.errors.correctionPoints !== undefined }) }>
          <input
            className="form-control"
            type="number"
            name="correctionPoints"
            value={ this.state.correctionPoints }
            onChange={ this.handleChange }
          />
        </td>
        <td>{this.state.totalPoints }</td>
        <td>
          { this.state.updating === true && <AdminUiIconLoaderComponent /> }
          { this.state.updating === false
            && <AdminUiIconComponent mod="checkmark" className="text-success" /> }
        </td>
      </tr>
    );
  }
}

export default AdminTmsTournamentBattleStandingContainer;
