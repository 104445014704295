import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { ADMIN_AUTOSUGGEST_THEME } from 'admin/services/theme';
import { countries } from 'admin/helpers/form/countries';

const getCountryName = (countryCode, countries) => {
  let countryName = '';
  if (countryCode && Array.isArray(countries) && countries.length) {
    countryName = countries.reduce((accum, country) => (
      country.key.toLowerCase() === countryCode.toLowerCase()
        ? country.value
        : accum),
    '');
  }
  return countryName;
};

export default class AdminUiFormAutocompleteCountriesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      suggestions: [],
      countryCode: ''
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.countryCode !== nextProps.countryCode) {
      return {
        countryCode: nextProps.countryCode,
        inputValue: getCountryName(nextProps.countryCode, countries)
      };
    }
    return null;
  }

  renderSuggestion = suggestion => (<span>{ suggestion.value }</span>);

  onChange = (event, { newValue }) => {
    const { onCountrySelected, name } = this.props;
    if (!newValue) {
      onCountrySelected({ name, value: null, tagName: 'select' });
    }
    this.setState({ inputValue: newValue });
  }

  onBlur = () => {
    const { countryCode } = this.props;
    if (!countryCode) {
      this.setState({
        inputValue: ''
      });
    } else {
      this.setState({
        inputValue: getCountryName(countryCode, countries)
      });
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  }

  getSuggestions = (value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    if (escapedValue === '') { return []; }
    const regex = new RegExp(`^${escapedValue}`, 'i');
    const filtered = countries.filter(item => regex.test(item.value));
    return filtered.slice(0, 5);
  }

  getSuggestionValue = (suggestion) => {
    const { onCountrySelected, name } = this.props;
    onCountrySelected({ name, value: suggestion.key, tagName: 'select' });
    return suggestion.value;
  }

  render() {
    const { placeholder } = this.props;
    const { suggestions, inputValue } = this.state;
    const inputProps = {
      placeholder,
      value: inputValue,
      onChange: this.onChange,
      onBlur: this.onBlur
    };

    return (
      <Autosuggest
        theme={ ADMIN_AUTOSUGGEST_THEME }
        suggestions={ suggestions }
        onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
        onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
        onSuggestionSelected={ this.selectCountry }
        getSuggestionValue={ this.getSuggestionValue }
        renderSuggestion={ this.renderSuggestion }
        inputProps={ inputProps }
      />
    );
  }
}

AdminUiFormAutocompleteCountriesComponent.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  onCountrySelected: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

AdminUiFormAutocompleteCountriesComponent.defaultProps = {
  countryCode: '',
  placeholder: ''
};
