import * as users from 'constants/admin/users';
import fetchApi from 'admin/api/api';

export const fetchUsersStart = () => ({ type: users.FETCH_START_ADMIN_USERS_LIST });
export const fetchUsersOk = (data = { results: [], countries: [], roles: [] }) => (
  { type: users.FETCH_OK_ADMIN_USERS_LIST, payload: data }
);
export const fetchUsersFail = errMessage => ({ type: users.FETCH_FAIL_ADMIN_USERS_LIST, payload: errMessage });

export const createQueryString = (filters, page, perPage) => {
  const queryParams = [];
  Object.keys(filters).forEach((key) => {
    if (filters[key]) queryParams.push(`by_${key}=${filters[key]}`);
  });
  if (page > 1) queryParams.push(`page=${page}`);
  const queryStr = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  queryParams.push(`admin_per_page=${perPage}`);
  const apiQueryStr = `?${queryParams.join('&')}`;
  return {
    apiQueryStr,
    queryStr
  };
};

export const fetchUsers = () => async (dispatch, getState) => {
  const { intl: { locale }, admin: { perPage }, adminUsersList: { page, filters } } = getState();
  dispatch(fetchUsersStart());
  const { apiQueryStr, queryStr } = createQueryString(filters, page, perPage);
  fetchApi(`/users.json${apiQueryStr}`, { method: 'GET' }).then((data) => {
    dispatch(fetchUsersOk({ ...data, page }));
    window.history.pushState({}, '', `/${locale}/users${queryStr}`);
  }).catch(({ networkError, error }) => {
    if (error) {
      dispatch(fetchUsersFail(error));
    } else {
      dispatch(fetchUsersFail(networkError || 'Something wrong'));
    }
  });
};

export const changeFilters = (newFilter = {}) => ({ type: users.UPDATE_FILTER_ADMIN_USERS_LIST, payload: newFilter });

export const changePage = page => ({ type: users.CHANGE_PAGE_ADMIN_USERS_LIST, payload: page });
