// admin users list constants
export const FETCH_START_ADMIN_USERS_LIST = 'FETCH_START_ADMIN_USERS_LIST';
export const FETCH_OK_ADMIN_USERS_LIST = 'FETCH_OK_ADMIN_USERS_LIST';
export const FETCH_FAIL_ADMIN_USERS_LIST = 'FETCH_FAIL_ADMIN_USERS_LIST';
export const UPDATE_FILTER_ADMIN_USERS_LIST = 'UPDATE_FILTER_ADMIN_USERS_LIST';
export const CHANGE_PAGE_ADMIN_USERS_LIST = 'CHANGE_PAGE_ADMIN_USERS_LIST';
// admin users new constants
// fetch initial data
export const START_FETCH_DATA_ADMIN_USERS_NEW = 'START_FETCH_DATA_ADMIN_USERS_NEW';
export const OK_FETCH_DATA_ADMIN_USERS_NEW = 'OK_FETCH_DATA_ADMIN_USERS_NEW';
export const FAIL_FETCH_DATA_ADMIN_USERS_NEW = 'FAIL_FETCH_DATA_ADMIN_USERS_NEW';
// fetch create new user
export const START_SUBMIT_FORM_ADMIN_USERS_NEW = 'START_SUBMIT_FORM_ADMIN_USERS_NEW';
export const OK_SUBMIT_FORM_ADMIN_USERS_NEW = 'OK_SUBMIT_FORM_ADMIN_USERS_NEW';
export const FAIL_SUBMIT_FORM_ADMIN_USERS_NEW = 'FAIL_SUBMIT_FORM_ADMIN_USERS_NEW';
// update avatar
export const UPDATE_BASE64_AVATAR_ADMIN_USERS_NEW = 'UPDATE_BASE64_AVATAR_ADMIN_USERS_NEW';
export const START_SUBMIT_AVATAR_ADMIN_USERS_NEW = 'START_SUBMIT_AVATAR_ADMIN_USERS_NEW';
export const OK_SUBMIT_AVATAR_ADMIN_USERS_NEW = 'OK_SUBMIT_AVATAR_ADMIN_USERS_NEW';
export const FAIL_SUBMIT_AVATAR_ADMIN_USERS_NEW = 'FAIL_SUBMIT_AVATAR_ADMIN_USERS_NEW';

// alerts
export const SHOW_ALERT_ADMIN_USERS_NEW = 'SHOW_ALERT_ADMIN_USERS_NEW';
export const CLOSE_ALERT_ADMIN_USERS_NEW = 'CLOSE_ALERT_ADMIN_USERS_NEW';

// fetch edit user constants
// fetch initial data
export const START_FETCH_DATA_ADMIN_USERS_EDIT = 'START_FETCH_DATA_ADMIN_USERS_EDIT';
export const OK_FETCH_DATA_ADMIN_USERS_EDIT = 'OK_FETCH_DATA_ADMIN_USERS_EDIT';
export const FAIL_FETCH_DATA_ADMIN_USERS_EDIT = 'FAIL_FETCH_DATA_ADMIN_USERS_EDIT';
// fetch create new user
export const START_SUBMIT_FORM_ADMIN_USERS_EDIT = 'START_SUBMIT_FORM_ADMIN_USERS_EDIT';
export const OK_SUBMIT_FORM_ADMIN_USERS_EDIT = 'OK_SUBMIT_FORM_ADMIN_USERS_EDIT';
export const FAIL_SUBMIT_FORM_ADMIN_USERS_EDIT = 'FAIL_SUBMIT_FORM_ADMIN_USERS_EDIT';
// update avatar
export const UPDATE_BASE64_AVATAR_ADMIN_USERS_EDIT = 'UPDATE_BASE64_AVATAR_ADMIN_USERS_EDIT';
export const START_SUBMIT_AVATAR_ADMIN_USERS_EDIT = 'START_SUBMIT_AVATAR_ADMIN_USERS_EDIT';
export const OK_SUBMIT_AVATAR_ADMIN_USERS_EDIT = 'OK_SUBMIT_AVATAR_ADMIN_USERS_EDIT';
export const FAIL_SUBMIT_AVATAR_ADMIN_USERS_EDIT = 'FAIL_SUBMIT_AVATAR_ADMIN_USERS_EDIT';

// alerts
export const SHOW_ALERT_ADMIN_USERS_EDIT = 'SHOW_ALERT_ADMIN_USERS_EDIT';
export const CLOSE_ALERT_ADMIN_USERS_EDIT = 'CLOSE_ALERT_ADMIN_USERS_EDIT';
// export const CHANGE_ADMIN_USERS_LIST_PER_PAGE = 'CHANGE_ADMIN_USERS_LIST_PER_PAGE';
