import { i18n } from 'core/services/i18n';
import countriesArray from 'core/countries/countries';


export const normalizeInputValue = (value, resetToDefaultValue, defaultValue) => {
  if (!resetToDefaultValue) {
    return value && typeof value === 'string' ? value.toLowerCase() : value;
  } if (!value) {
    return defaultValue;
  }
  return value && typeof value === 'string' ? value.toLowerCase() : value;
};

export const associateCountryCodeWithName = countriesCodes => countriesCodes.map(key => ({
  key,
  value: i18n(`countries.${key}`)
}));

export const countries = associateCountryCodeWithName(countriesArray)
  .sort((a, b) => { if (a.value && b.value) a.value.toUpperCase() >= b.value.toUpperCase() ? 1 : -1 });
