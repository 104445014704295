import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { times } from 'lodash';
import { i18n } from 'core/services/i18n';

export default class AdminLinkableComponent extends Component {
  static propTypes = {
    homeBlock: PropTypes.shape({
      isAvailable: PropTypes.bool.isRequired,
      messageForNotAvailable: PropTypes.string
    }).isRequired,
    linksBlock: PropTypes.shape({
      isAvailable: PropTypes.bool.isRequired,
      messageForNotAvailable: PropTypes.string
    }).isRequired,
    instanceClassName: PropTypes.string.isRequired,
    selectedLandingPlace: PropTypes.shape({
      site: PropTypes.shape({
        id: PropTypes.string,
        alias: PropTypes.string
      }),
      region: PropTypes.shape({
        id: PropTypes.string,
        alias: PropTypes.string
      })
    }).isRequired,
    selectedLinks: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types

    tableData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    handleCheck: PropTypes.func.isRequired,
    handleUncheck: PropTypes.func.isRequired,

    selectData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    handleSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired // eslint-disable-line react/forbid-prop-types,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };
  }

  handleToggle = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));
  }

  tableRows = (data) => {
    const rows = [];
    data.forEach((item) => {
      const spaces = [];
      times(item.deep, () => { spaces.push(<td key={ Math.random() } />); });
      rows.push(
        <tr key={ item.key }>
          { spaces }
          <td key={ Math.random() } colSpan={ 3 - item.deep }>{ item.label }</td>
          <td key={ Math.random() }>
            <input
              type="checkbox"
              disabled={ !this.props.linksBlock.isAvailable || !item.isSelectable || this.props.disabled }
              checked={ this.props.selectedLinks.includes(item.not_hot) }
              value={ item.not_hot }
              name={ `${this.props.instanceClassName}[links][]` }
              onChange={ (event) => {
                if (event.target.checked) {
                  this.props.handleCheck(item.not_hot);
                } else {
                  this.props.handleUncheck(item.not_hot);
                }
              } }
            />
          </td>
          <td key={ Math.random() }>
            <input
              type="checkbox"
              disabled={ !this.props.linksBlock.isAvailable || !item.isSelectable || this.props.disabled }
              checked={ this.props.selectedLinks.includes(item.hot) }
              value={ item.hot }
              name={ `${this.props.instanceClassName}[links][]` }
              onChange={ (event) => {
                if (event.target.checked) {
                  this.props.handleCheck(item.hot);
                } else {
                  this.props.handleUncheck(item.hot);
                }
              } }
            />
          </td>
        </tr>
      );
    });
    return rows;
  }

  selectOptions = (data) => {
    const options = [];

    const emptyDataAttrs = {
      'data-site-id': '', 'data-site-alias': '', 'data-region-id': '', 'data-region-alias': ''
    };
    options.push(<option key="empty" { ...emptyDataAttrs } value="">{ i18n('linkable.not_specified') }</option>);

    data.forEach((item) => {
      const dataAttrs = {
        'data-site-id': item.site.id,
        'data-site-alias': item.site.alias,
        'data-region-id': item.region.id,
        'data-region-alias': item.region.alias
      };
      options.push(<option key={ item.key } { ...dataAttrs } value={ item.link }>{ item.label }</option>);
    });
    return options;
  }

  render() {
    const {
      tableData,
      selectData,
      selectedLandingPlace,
      handleSelect,
      instanceClassName
    } = this.props;
    const { isVisible } = this.state;

    const boxStyle = {
      backgroundColor: '#f7f7f7'
    };

    const linksStyle = {
      backgroundColor: 'white'
    };

    return (
      <div className="box box-default" style={ boxStyle }>
        <div className="box-header with-border" onClick={ this.handleToggle }>
          <b>{ i18n('admin.linkable.links') }</b>
          <div className="box-tools pull-right">
            <span className="btn btn-box-tool">
              <i className="fa fa-plus" />
            </span>
          </div>
        </div>
        <div className={ classNames('box-body', { hidden: !isVisible }) }>
          <div>
            <div>
              <b>{ i18n('linkable.home') }</b>
            </div>
            <input name={ `${instanceClassName}[landing_place][link]` } type="hidden" value={ selectedLandingPlace.link } />
            <input name={ `${instanceClassName}[landing_place][site][id]` } type="hidden" value={ selectedLandingPlace.site.id } />
            <input name={ `${instanceClassName}[landing_place][site][alias]` } type="hidden" value={ selectedLandingPlace.site.alias } />
            <input name={ `${instanceClassName}[landing_place][region][id]` } type="hidden" value={ selectedLandingPlace.region.id } />
            <input name={ `${instanceClassName}[landing_place][region][alias]` } type="hidden" value={ selectedLandingPlace.region.alias } />
            <select
              disabled={ !this.props.homeBlock.isAvailable || this.props.disabled }
              className="form-control select"
              value={ selectedLandingPlace.link || '' }
              onChange={ (event) => { handleSelect(event); } }
            >
              { this.selectOptions(selectData) }
            </select>
          </div>
          <input name={ `${instanceClassName}[links][]` } type="hidden" value="" />
          <br />
          <div className="table-responsive">
            <table className="table" style={ linksStyle }>
              <thead>
                <tr>
                  <th colSpan={ 3 }>
                    {
                      this.props.linksBlock.messageForNotAvailable &&
                      <span style={ { fontWeight: 'normal' } }> *{ this.props.linksBlock.messageForNotAvailable } (deprecated)</span>
                    }
                  </th>
                  <th>Link</th>
                  <th>Hot Link</th>
                </tr>
              </thead>
              <tbody>
                { this.tableRows(tableData) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
