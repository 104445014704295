import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminLinkableComponent from 'admin/components/linkable';

import { SITE } from 'constants/site';

// Этот компонент отличается от реализованного на рельсах компонента тем, что тут можно выбрать "Дом"
// https://starladder.atlassian.net/wiki/spaces/ST3P/pages/869793806
// Компонент реализован (пока) для событий и турниров, соответственно тут нет возможности сделать
// привязку к событию. Линки с событиями не выводятся и не попадают в меню выбора "Дома"
// @TODO сделать оба компонента на реакте, или даже унифицировать в один
export default class AdminLinkableContainer extends Component {
  static propTypes = {
    homeBlock: PropTypes.shape({
      isAvailable: PropTypes.bool.isRequired,
      messageForNotAvailable: PropTypes.string
    }).isRequired,
    linksBlock: PropTypes.shape({
      isAvailable: PropTypes.bool.isRequired,
      messageForNotAvailable: PropTypes.string
    }).isRequired,
    instanceClassName: PropTypes.string.isRequired,
    links: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    selectedLinks: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    selectedLandingPlace: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
    disabled: PropTypes.bool.isRequired // eslint-disable-line react/forbid-prop-types,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedLinks: this.props.selectedLinks,
      selectedLandingPlace: this.props.selectedLandingPlace
    };
  }

  handleCheck = (link) => {
    this.setState(prevState => ({
      selectedLinks: [...prevState.selectedLinks, link]
    }));
  }

  handleUncheck = (link) => {
    this.setState({
      selectedLinks: this.state.selectedLinks.filter(item => !link.includes(item))
    }, () => {
      const selectDataArr = this.prepareLandingPlaceSelectData(this.props.links);
      const isLandingPlaceAvailabeForSelect = selectDataArr.find(item => item.link === this.state.selectedLandingPlace.link);
      if (selectDataArr.length === 0 || !isLandingPlaceAvailabeForSelect) {
        this.setState({
          selectedLandingPlace: { link: '', site: { id: '', alias: '' }, region: { id: '', alias: '' } }
        });
      }
    });
  }

  handleSelect = (event) => {
    const siteId = event.target[event.target.selectedIndex].getAttribute('data-site-id');
    const siteAlias = event.target[event.target.selectedIndex].getAttribute('data-site-alias');
    const regionId = event.target[event.target.selectedIndex].getAttribute('data-region-id');
    const regionAlias = event.target[event.target.selectedIndex].getAttribute('data-region-alias');
    this.setState({
      selectedLandingPlace: { link: event.target.value, site: { id: siteId, alias: siteAlias }, region: { id: regionId, alias: regionAlias } }
    });
  }

  prepareLandingPlaceSelectData = (links, label = '', level = 0) => {
    const {
      selectedLinks
    } = this.state;

    const {
      homeBlock
    } = this.props;

    const options = [];
    links.forEach((item) => {
      const fullLabel = label ? [label.toString(), item.name].join(' > ') : item.name;

      const isSelectedLinksIncludesLink = selectedLinks.includes(item.link)
        || selectedLinks.includes(item.not_hot)
        || selectedLinks.includes(item.hot);

      if (!homeBlock.isAvailable || isSelectedLinksIncludesLink) {
        options.push({
          key: item.link,
          label: fullLabel,
          link: item.link,
          site: { id: item.site.id || '', alias: item.site.alias || '' },
          region: { id: item.region.id || '', alias: item.region.alias || '' }
        });
      }
      if (item.entities && item.entities.length > 0) {
        this.prepareLandingPlaceSelectData(item.entities, fullLabel, level + 1).forEach(r => options.push(r));
      }
    });
    return options;
  }

  prepareLinksTableData = (links, level = 0) => {
    const rows = [];
    links.filter(item => !item.link.includes('e')).forEach((item) => {
      const isSite = !item.link.includes('r') && !item.link.includes('e');
      let isSelectable = true;
      if (isSite && item.site.type !== SITE.TYPES.MAIN && item.site.type !== SITE.TYPES.MULTIEVENT) {
        isSelectable = false;
      }
      rows.push({
        key: item.link, label: item.name, not_hot: item.not_hot, hot: item.hot, deep: level, isSelectable
      });
      if (item.entities && item.entities.length > 0) {
        this.prepareLinksTableData(item.entities, level + 1).forEach(r => rows.push(r));
      }
    });
    return rows;
  }

  render() {
    const {
      links,
      instanceClassName,
      homeBlock,
      linksBlock,
      disabled
    } = this.props;

    const {
      selectedLinks,
      selectedLandingPlace
    } = this.state;

    return (
      <AdminLinkableComponent
        homeBlock={ homeBlock }
        linksBlock={ linksBlock }
        selectedLinks={ selectedLinks }
        selectedLandingPlace={ selectedLandingPlace }
        instanceClassName={ instanceClassName }
        disabled={ disabled }

        selectData={ this.prepareLandingPlaceSelectData(links) }
        handleCheck={ this.handleCheck }
        handleUncheck={ this.handleUncheck }

        tableData={ this.prepareLinksTableData(links) }
        handleSelect={ this.handleSelect }
      />
    );
  }
}
