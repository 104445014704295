import PropTypes from 'prop-types';
import React from 'react';

import AdminUiFormGroupComponent from 'admin/components/ui/form/group';
import AdminUiFormRadioComponent from 'admin/components/ui/form/radio';
import AdminUiFormAutocompleteComponent from 'admin/components/ui/form/autocomplete/main';
import AdminUiFormAutocompleteCitiesComponent from 'admin/components/ui/form/autocomplete/cities';
import AdminUiFormAutocompleteCountriesComponent from 'admin/components/ui/form/autocomplete/countries';
import AdminUiFormAutocompleteMultiComponent from 'admin/components/ui/form/select/multi';
import AdminUiFormDatepickerComponent from 'admin/components/ui/form/datepicker';
import AdminUiFormCheckboxComponent from 'admin/components/ui/form/checkbox';
import AdminUiFormInlineComponent from 'admin/components/ui/form/inline';
import AdminUiFormAutocompleteUsersComponent from 'admin/components/ui/form/autocomplete/users/users';


export default class AdminUiFormMainComponent extends React.Component {
    onSubmit = (e) => {
      const { onSubmit } = this.props;
      e.preventDefault();
      onSubmit();
    }

    render() {
      const { children } = this.props;
      return (
        <form autoComplete="off" onSubmit={ this.onSubmit }>
          { children }
        </form>
      );
    }
}

AdminUiFormMainComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func
};

AdminUiFormMainComponent.defaultProps = {
  onSubmit: () => {}
};

export {
  AdminUiFormMainComponent as Main,
  AdminUiFormGroupComponent as Group,
  AdminUiFormRadioComponent as Radio,
  AdminUiFormCheckboxComponent as Checkbox,
  AdminUiFormAutocompleteComponent as Autocomplete,
  AdminUiFormAutocompleteCitiesComponent as AutocompleteCities,
  AdminUiFormAutocompleteCountriesComponent as AutocompleteCountries,
  AdminUiFormAutocompleteMultiComponent as AutocompleteMulti,
  AdminUiFormDatepickerComponent as Datepicker,
  AdminUiFormInlineComponent as Inline,
  AdminUiFormAutocompleteUsersComponent as Users
};
