import PropTypes from 'prop-types';
import React from 'react';

import { i18n } from 'core/services/i18n';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminTmsTournamentBattleStandingContainer from './battle_standing';

class AdminTmsTournamentBattleStandingsContainer extends React.Component {
  static propTypes = {
    format: PropTypes.string,
    isEarningMoney: PropTypes.bool.isRequired,
    standings: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.number,
      player: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        country: PropTypes.string
      }),
      tournamentGroup: PropTypes.shape({
        index: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
      }),
      battlesCount: PropTypes.number,
      qualified: PropTypes.bool,
      position: PropTypes.number,
      positions: PropTypes.number,
      firstPositions: PropTypes.number,
      kills: PropTypes.number,
      positionPoints: PropTypes.number,
      killsPoints: PropTypes.number,
      bonusPoints: PropTypes.number,
      money: PropTypes.number,
      correctionPoints: PropTypes.number,
      totalPoints: PropTypes.number
    }))
  }

  static defaultProps = {
    format: null,
    standings: []
  }

  render() {
    const { format } = this.props;
    const { isEarningMoney } = this.props;
    const showGroup = format === 'battle_royale_pools_global';

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{ i18n('simple_form.labels.tms_battle_standing.position') }</th>
            { showGroup && <th>{ i18n('simple_form.labels.tms_battle_standing.group') }</th> }
            <th>{ i18n('simple_form.labels.tms_battle_standing.player') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.battles_count') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.positions') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.first_positions') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.kills') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.positions_points') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.kills_points') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.bonus_points') }</th>
            <th>{ isEarningMoney && i18n('simple_form.labels.tms_battle_standing.money') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.correction_points') }</th>
            <th>{ i18n('simple_form.labels.tms_battle_standing.total_points') }</th>
            <th>
              <AdminUiIconComponent mod="checkmark" />
            </th>
          </tr>
        </thead>

        <tbody>
          {
            this.props.standings.map((standing) => {
              return (
                <AdminTmsTournamentBattleStandingContainer
                  key={ standing.id }
                  url={ standing.url }
                  showGroup={ showGroup }
                  { ...standing.data }
                />
              );
            })
          }
        </tbody>
      </table>
    );
  }
}

export default AdminTmsTournamentBattleStandingsContainer;
