import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import _ from 'lodash';

export default class AdminUiFormAutocompleteMultiComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeSelected: true,
      suggestions: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { suggestions, value } = props;
    if (value === state.value && _.isEqual(suggestions, state.suggestions)) {
      return null;
    }
    let suggestionsNew = !suggestions ? Object.assign([], state.suggestions) : suggestions;

    suggestionsNew = suggestionsNew.map((suggestion) => {
      let listItem;
      if (typeof suggestion === 'string') {
        listItem = { value: suggestion, label: suggestion };
        return { ...listItem };
      }
      return { ...suggestion, value: suggestion.id, label: suggestion.name };
    });
    return ({ suggestions: suggestionsNew, value });
  }

  shouldComponentUpdate(nextProps) {
    const { value, suggestion } = this.state;
    return !(nextProps.value === value && _.isEqual(nextProps.suggestions, suggestion));
  }

  handleSelectChange = (value) => {
    const { name, onSelect } = this.props;
    this.setState({
      value
    }, () => {
      onSelect({ name, value, tagName: 'select' });
    });
  }

  render() {
    const {
      multi,
      disabled,
      placeholder
    } = this.props;
    const {
      value,
      removeSelected,
      suggestions
    } = this.state;

    return (
      <Select
        simpleValue
        closeOnSelect
        multi={ multi }
        disabled={ disabled }
        onChange={ this.handleSelectChange }
        options={ suggestions }
        placeholder={ placeholder }
        removeSelected={ removeSelected }
        value={ value }
      />
    );
  }
}

AdminUiFormAutocompleteMultiComponent.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string,
  multi: PropTypes.bool,
  disabled: PropTypes.bool
};

AdminUiFormAutocompleteMultiComponent.defaultProps = {
  value: 0,
  placeholder: '',
  name: '',
  multi: false,
  disabled: false
};
