import ReactOnRails from 'react-on-rails';
import qs from 'qs';

import createQueryJsonApiString from '../../helpers/json_api_utils/createQueryJsonApiString';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': ReactOnRails.authenticityToken(),
  'X-Requested-With': 'XMLHttpRequest'
};

const defaultOptions = {
  mode: 'cors',
  credentials: 'same-origin',
  headers: defaultHeaders
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
const parseJSON = response => new Promise((resolve, reject) => response.json()
  .then(json => resolve({
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    json
  }))
  .catch(error => reject({
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    error
  })));

export const createQueryString = (queryParams, queryApiJson) => {
  let queryString = '';
  if (Object.keys(queryParams).length) {
    queryString = `?${qs.stringify(queryParams)}`;
  }
  if (queryString.length && queryApiJson) {
    queryString += `&${createQueryJsonApiString(queryApiJson)}`;
  } else if (queryApiJson) {
    queryString = `?${createQueryJsonApiString(queryApiJson)}`;
  }
  return queryString;
};

const fetchApi = (
  url,
  {
    queryParams = {},
    queryApiJson,
    method = 'GET',
    body,
    historyReplaceState = false
  } = {},
) => new Promise((resolve, reject) => {
  let options = { ...defaultOptions, method };
  if (method === 'POST' || 'PUT') {
    if (body instanceof FormData) {
      options = {
        ...options,
        headers: { ...defaultHeaders },
        body
      };
      delete options.headers['Content-Type'];
    } else {
      options = { ...options, body: JSON.stringify(body) };
    }
  }

  const queryString = createQueryString(queryParams, queryApiJson);

  const endPoint = `${url}${queryString}`;

  fetch(endPoint, options)
    .then(parseJSON)
    .then((response) => {
      if (response.ok) {
        if (historyReplaceState) {
          window.history.replaceState({}, '', queryString);
        }
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(response.json);
    })
    .catch((response) => {
      const errObj = {
        networkError: response.statusText
      };
      reject(errObj);
    });
});

export default fetchApi;
