import React from 'react';
import { i18n } from 'core/services/i18n';

export default class AdminUsersListHeadComponent extends React.Component {
  render() {
    return (
      <tr className="text-center">
        <th>{ i18n('admin.users.index.avatar') }</th>
        <th>{ i18n('admin.users.index.id') }</th>
        <th>{ i18n('admin.users.index.email') }</th>
        <th>{ i18n('admin.users.index.nick') }</th>
        <th>{ i18n('admin.users.index.birthday') }</th>
        <th>{ i18n('admin.users.index.full_name') }</th>
        <th>{ i18n('admin.users.index.country') }</th>
        <th>{ i18n('admin.users.index.skype') }</th>
        <th>{i18n('admin.users.index.discord_id')}</th>
        <th />
      </tr>
    );
  }
}
