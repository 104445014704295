import React from 'react';
import * as PropTypes from 'prop-types';

import * as Form from 'admin/components/ui/form/main';

export default class CheckboxFormGroupReduxFormContainer extends React.Component {
  render() {
    const {
      input,
      label,
      required: requiredFromProps,
      meta: { touched, error }
    } = this.props;

    return (
      <Form.Group
        error={ !!error && touched }
        errorMsgs={ error }
        required={ requiredFromProps }
      >
        <Form.Checkbox
          { ...input }
          checked={ input.value }
          checkboxText={ label }
        />
      </Form.Group>
    );
  }
}

CheckboxFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func
  }).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool
  }).isRequired
};

CheckboxFormGroupReduxFormContainer.defaultProps = {
  required: false
};
