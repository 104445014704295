import PropTypes from 'prop-types';
import React from 'react';
import Cropper from 'react-cropper';
import classNames from 'classnames';

import { i18n, replaceParams } from 'core/services/i18n';
import isImageFile from 'admin/helpers/utils/is_image_file';

export default class AdminUiCropperComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fileName: i18n('cropper.form.no_file_selected'),
      cropperSrc: props.src,
      cropperPreview: props.src,
      cropBoxCoef: 1.111111,
      noFileSelected: true,
      showCropper: true,
      cropperKey: 0,
      index: 0,
      status: '',
      file: props.src,
      fileFormat: 'image/jpeg'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status };
    }
    if (nextProps.src !== prevState.src) {
      return {
        cropperSrc: nextProps.src,
        cropperPreview: nextProps.src,
        cropperAvatar: nextProps.src,
        src: nextProps.src,
        file: nextProps.src
      };
    }
    return null;
  }

  getCropperSrc = (result) => {
    this.setState({ cropperSrc: result });
  };

  setImagePreview = () => {
    const { index, fileFormat } = this.state;
    if (this.cropper && index > 0) {
      const canvas = this.cropper.getCroppedCanvas();
      try {
        canvas.toBlob((blob) => {
          this.setState({
            cropperPreview: canvas.toDataURL(),
            file: blob
          });
        }, fileFormat); // we need to save the format of uploaded image without changing it
      } catch (e) {
        console.error('error in AdminUiCropperComponent at setImagePreview method', e);
      }
    }
    this.setState(state => ({
      index: state.index + 1
    }));
  };

  removeAvatar = () => {
    const { callBackFunc, src } = this.props;
    this.handleModalToggle();
    this.setState(state => ({
      noFileSelected: true,
      cropperAvatar: src,
      cropperSrc: src,
      cropperPreview: src,
      file: src,
      index: 0,
      modal: false
    }), () => {
      callBackFunc(null);
    });
  };

  setAvatar = () => {
    const { callBackFunc } = this.props;
    const { cropperPreview, file } = this.state;
    this.setState(state => ({
      cropperAvatar: cropperPreview,
      noFileSelected: true,
      index: 0
    }), async () => {
      await callBackFunc(file);
      setTimeout(() => {
        this.handleModalToggle();
      }, 1000);
    });
  };

  getCropper = (node) => { this.cropper = node; }

  addAvatar = () => {
    this.setState({
      showCropper: false
    }, () => {
      const file = this.uploadAvatar.files[0];
      if (file) {
        const fileSizeLimit = 1024 * 1024 * 5;
        if (!isImageFile(file)) {
          this.setState({
            fileName: i18n('image_cropper.error.format'),
            showCropper: true
          });
        } else if (file.size > fileSizeLimit) {
          this.setState({
            fileName: replaceParams(i18n('image_cropper.error.weight'), { min: '5MB' }),
            showCropper: true
          });
        } else {
          const img = new Image();
          img.src = window.URL.createObjectURL(file);
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            if (width < 270 || height < 270) {
              this.setState({
                fileName: replaceParams(i18n('image_cropper.error.size_min'), { min: 270 }),
                showCropper: true
              });
            } else {
              const reader = new FileReader();
              reader.onload = () => {
                this.getCropperSrc(reader.result);
              };
              reader.readAsDataURL(file);
              this.setState({
                fileName: file.name,
                cropBoxCoef: 300 / height,
                showCropper: true,
                noFileSelected: false,
                fileFormat: file.type
              });
            }
          };
        }
        this.uploadAvatar.value = '';
      }
    });
  };

  handleModalToggle = () => {
    const { modal, status } = this.state;
    const { disabled } = this.props;
    if (disabled) return;
    this.setState(state => ({
      modal: !modal,
      showCropper: !modal,
      cropperKey: state.cropperKey + 1,
      index: 0,
      status: modal ? '' : status
    }));
    document.body.classList.toggle('modal-open');
  }

  render() {
    const {
      cropperAvatar,
      modal,
      fileName,
      cropperSrc,
      cropperPreview,
      cropBoxCoef,
      noFileSelected,
      showCropper,
      cropperKey,
      status
    } = this.state;
    const { disabled, darkTheme } = this.props;
    return (
      <div>
        <div className={ classNames('thumbnail', { 'dark-theme': darkTheme }) }>
          <div className="thumb">
            <img src={ cropperAvatar } alt="" />
            <div className="thumb-options">
              { !disabled
                && (
                <span>
                  <button className="btn btn-icon btn-success" type="button" onClick={ this.handleModalToggle }>
                    <i className="fa fa-edit" />
                  </button>
                </span>
                )
              }

            </div>
          </div>
        </div>
        <div className={ classNames('modal', 'fade', { 'is-active in': modal }) }>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button className="close" type="button" onClick={ this.handleModalToggle }>×</button>
                <h4 className="modal-title">
                  { i18n('cropper.form.upload_avatar')}
                </h4>
              </div>
              <div className="uploader">
                <input
                  type="file"
                  ref={ (input) => { this.uploadAvatar = input; } }
                  onChange={ this.addAvatar }
                />
                <span className="filename">{ fileName }</span>
                <span className="btn btn-info btn-sm">
                  <i className="fa fa-plus" />
                </span>
              </div>
              <div className="modal-body clearfix">
                {showCropper && (
                  <React.Fragment>
                    <Cropper
                      ref={ this.getCropper }
                      src={ cropperSrc }
                      className="cropper-holder"
                      aspectRatio={ 1 }
                      guides={ false }
                      dragMode="none"
                      crop={ this.setImagePreview }
                      zoomOnWheel={ false }
                      minCropBoxWidth={ cropBoxCoef * 271 }
                      minContainerWidth={ cropBoxCoef * 271 }
                      minContainerHeight={ cropBoxCoef * 271 }
                      // autoCrop={ false }
                      viewMode={ 1 }
                      key={ cropperKey }
                    />
                    <div className="cropper-preview-box">
                      <img src={ cropperPreview } alt="preview" className="cropper-preview" />
                      { status === 'error'
                      && (
                      <span
                        className="btn btn-sm btn-danger cropper-preview-status"
                        style={ { pointerEvents: 'none' } }
                      >
                        <i className="fa fa-close" />
                      </span>
                      )
                      }
                      { status === 'success'
                      && (
                      <span
                        style={ { pointerEvents: 'none' } }
                        className="btn btn-sm btn-success cropper-preview-status"
                      >
                        <i className="fa fa-check" />
                      </span>
                      )
                      }
                    </div>
                  </React.Fragment>
                ) }
              </div>
              <div
                className="modal-footer"
                style={ {
                  display: 'flex',
                  justifyContent: 'center'
                } }
              >
                <button
                  className="btn btn-lg btn-success"
                  disabled={ noFileSelected }
                  type="button"
                  onClick={ this.setAvatar }
                >
                  { i18n('image_cropper.save')}
                </button>
                <button
                  className="btn btn-lg btn-danger"
                  type="button"
                  onClick={ this.removeAvatar }
                >
                  { i18n('image_cropper.delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminUiCropperComponent.propTypes = {
  src: PropTypes.string.isRequired,
  callBackFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

AdminUiCropperComponent.defaultProps = {
  disabled: false
};
