import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AdminUsersFormContainer from 'admin/containers/users/form/user_form';
import * as adminUserActions from 'actions/admin/users/new';

class AdminUsersNewContainer extends React.Component {
  render() {
    const {
      routes,
      countries,
      actions,
      notification,
      isSubmitError,
      isErrored
    } = this.props;
    return (
      <div>
        <AdminUsersFormContainer
          countries={ countries }
          avatarSrc={ routes.defAvatarUrl }
          initialValues={ { gender: 'other' } }
          notification={ notification }
          isErrored={ isErrored || isSubmitError }
          handleSetAvatar={ actions.updateBase64Avatar }
          handleSubmitForm={ actions.submitForm }
          handleqCloseAlert={ actions.closeAlert }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.adminUserNew.countries,
  routes: state.adminUserNew.routes,
  isSubmitError: state.adminUserNew.isSubmitError,
  isErrored: state.adminUserNew.isErrored,
  notification: state.adminUserNew.notification
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(adminUserActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersNewContainer);

AdminUsersNewContainer.propTypes = {
  notification: PropTypes.string,
  isSubmitError: PropTypes.bool.isRequired,
  isErrored: PropTypes.bool.isRequired,
  routes: PropTypes.shape({
    backUrl: PropTypes.string,
    formUrl: PropTypes.string,
    defAvatarUrl: PropTypes.string
  }),
  countries: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })),
  actions: PropTypes.shape({
    updateBase64Avatar: PropTypes.func,
    submitForm: PropTypes.func,
    closeAlert: PropTypes.func
  }).isRequired
};

AdminUsersNewContainer.defaultProps = {
  countries: [],
  notification: null,
  routes: {
    backUrl: '',
    formUrl: '',
    defAvatarUrl: ''
  }
};
