import React from 'react';
import PropTypes from 'prop-types';

const AdminUiListLoader = ({ colSpan }) => (
  <tr>
    <td className="text-center" colSpan={ colSpan }>
      <i style={ { fontSize: 30, padding: 20 } } className="fa fa-refresh fa-spin" />
    </td>
  </tr>
);

AdminUiListLoader.propTypes = {
  colSpan: PropTypes.number.isRequired
};

export default AdminUiListLoader;
