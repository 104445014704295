import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

class AdminUiIconComponent extends React.Component {
  static propTypes = {
    mod: PropTypes.string,
    spin: PropTypes.bool,
    className: PropTypes.string,
    adminLte: PropTypes.bool
  }

  static defaultProps = {
    mod: 'pacman',
    spin: false,
    className: null,
    adminLte: false
  }

  render() {
    let icon;
    if (this.props.adminLte) {
      icon = `fa fa-${this.props.mod}`;
    } else {
      icon = `icon-${this.props.mod}`;
    }

    return (
      <i className={ classNames(icon, this.props.className, { spin: this.props.spin }) } />
    );
  }
}

export default AdminUiIconComponent;
