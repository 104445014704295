export const TZ = 'tz';
export const DATE_TIME_SELECTOR = 'react-date-time-component';
export const DEFAULT_DATE_TIME_FORMAT = 'date_short_1';
export const TIME_DATE_FORMATS = {
  datetime_default: {
    ru: 'D MMMM YYYY H:mm',
    en: 'MMMM D, YYYY H:mm'
  },
  date_default: {
    ru: 'D MMMM YYYY',
    en: 'MMMM D, YYYY'
  },
  time_default: {
    ru: 'H:mm',
    en: 'H:mm'
  },
  datetime_system: {
    ru: 'DD.MM.YYYY H:mm:ss.SSS',
    en: 'YYYY-MM-DD H:mm:ss.SSS'
  },
  datetime_1: {
    ru: 'DD.MM.YYYY H:mm:ss',
    en: 'YYYY-MM-DD H:mm:ss'
  },
  datetime_2: {
    ru: 'dd, D MMMM YYYY H:mm',
    en: 'dd, MMMM D, YYYY h:mm A'
  },
  datetime_3: {
    ru: 'DD.M.YYYY H:mm:ss',
    en: 'M/DD/YYYY h:mm:ss A'
  },
  datetime_4: {
    ru: 'D MMMM, H:mm',
    en: 'MMMM D, H:mm'
  },
  datetime_5: {
    ru: 'D MMMM YYYY, H:mm',
    en: 'MMMM D, YYYY, H:mm'
  },
  datetime_6: {
    ru: 'D MMM, H:mm',
    en: 'MMM D, H:mm'
  },
  datetime_7: {
    ru: 'DD/MM/YYYY H:mm',
    en: 'DD/MM/YYYY H:mm'
  },
  date_short_1: {
    ru: 'D MMMM', // 12 августа
    en: 'MMMM D'
  },
  date_short_2: {
    ru: 'DD.MM',
    en: 'MM/DD'
  },
  date_short_3: {
    ru: 'dd, D MMMM',
    en: 'dd, MMMM D'
  },
  date_short_4: {
    ru: 'D MMM', // 12 авг.
    en: 'MMM D' // Aug 12
  },
  date_short_5: {
    ru: 'D MMM YYYY', // 12 Aвг 2001
    en: 'MMM D YYYY' // Aug 12 2001
  },
  date_full_1: {
    ru: 'dddd, D MMMM YYYY',
    en: 'dddd, MMMM D, YYYY'
  },
  date_full_2: {
    ru: 'DD.MM.YYYY',
    en: 'YYYY-MM-DD'
  },
  time_short_1: {
    ru: 'H:mm',
    en: 'h:mm A'
  },
  time_full_1: {
    ru: 'H:mm:ss',
    en: 'h:mm:ss A'
  },
  time_full_2: {
    ru: 'H:mm:ss',
    en: 'H:mm:ss'
  }
};
