import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminUIUserCellComponent from 'admin/components/ui/list/user_cell';

class AdminTeamsListItemComponent extends Component {
  render() {
    const {
      team: {
        id,
        game_id: gameId,
        name,
        clantag,
        country_name: countryName,
        edit_link: editLink,
        logo,
        discipline,
        region,
        captain
      }
    } = this.props;
    return (
      <tr className="text-center">
        <td>
          <img alt="logo" src={ logo } />
        </td>
        <td>{id}</td>
        <td>{gameId}</td>
        <td>
          <span className={ `discipline ${discipline.alias}` } />
        </td>
        <td>{region.name}</td>
        <td>
          {captain
          && (
          <AdminUIUserCellComponent
            url={ captain.url }
            nick={ captain.nick }
            logo={ captain.logo }
            fullName={ captain.full_name }
          />
          )}
        </td>
        <td>{name}</td>
        <td>{clantag}</td>
        <td>{countryName}</td>
        <td>
          <a className="btn btn-sm btn-info" title="Редактировать" href={ editLink }>
            <i className="fa fa-cog" />
          </a>
        </td>
      </tr>
    );
  }
}

AdminTeamsListItemComponent.propTypes = {
  team: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default AdminTeamsListItemComponent;
