import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import * as Form from 'admin/components/ui/form/main';

class AutocompleteCityFormGroupReduxFormContainer extends Component {
  render() {
    const {
      input,
      label,
      placeholder,
      required: requiredFromProps,
      meta: { touched, error },
      country,
      disabled,
      onCitySelected,
      onCityRemove
    } = this.props;
    return (
      <Form.Group
        labelStr={ label }
        error={ !!error && touched }
        errorMsgs={ error }
        required={ requiredFromProps }
      >
        <Form.AutocompleteCities
          name={ input.name }
          country={ country }
          city={ input.value || null }
          onChange={ input.onChange }
          onCitySelected={ onCitySelected }
          onCityRemove={ onCityRemove }
          placeholder={ placeholder }
          disabled={ disabled }
        />
      </Form.Group>
    );
  }
}

AutocompleteCityFormGroupReduxFormContainer.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool
  }).isRequired,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  onCitySelected: PropTypes.func,
  onCityRemove: PropTypes.func
};

AutocompleteCityFormGroupReduxFormContainer.defaultProps = {
  placeholder: '',
  required: false,
  country: '',
  disabled: false,
  onCitySelected: () => {},
  onCityRemove: () => {}
};

export default AutocompleteCityFormGroupReduxFormContainer;
