import React from 'react';
import PropTypes from 'prop-types';

export default class CoreSBCountryFlag extends React.Component {
  static propTypes = {
    klassName: PropTypes.string,
    country: PropTypes.shape({
      flag: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string
    })
  }

  render() {

    return (
      <img
        width="16"
        height="11"
        className={ this.props.klassName }
        src={ this.props.country.flag }
        alt={ this.props.country.name }
        title={ this.props.country.name }
      />
    );
  }
}
