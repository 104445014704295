import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminUiBtnComponent from 'admin/components/ui/btn';

class AdminUiFormUserPreviewComponent extends Component {
  render() {
    const { user, onRemoveUser } = this.props;
    return (
      <div className="row">
        <div className="col-sm-9">
          <a className="def-flex" href={ user.edit_url || user.editLink }>
            <div className="thumbnail mod-small">
              <img alt="" src={ user.logo } style={ { width: 46 } } />
            </div>
            <h4 className="caption text-center">
              {
                user.fullName || user.full_name
                  ? `${user.fullName || user.full_name} `
                  : `${user.first_name || ''} ${user.last_name || ''}`
              }
              <small>
                {`${user.nick || ''}`}
              </small>
            </h4>
          </a>
        </div>
        <div className="col-sm-1 col-sm-offset-2">
          <AdminUiBtnComponent
            size="sm"
            mod="danger"
            onClick={ onRemoveUser }
          >
            <i className="fa fa-close" />
          </AdminUiBtnComponent>
        </div>
      </div>
    );
  }
}

export default AdminUiFormUserPreviewComponent;

AdminUiFormUserPreviewComponent.propTypes = {
  user: PropTypes.shape({
    edit_url: PropTypes.string,
    logo: PropTypes.string,
    fullName: PropTypes.string,
    full_name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    nick: PropTypes.string
  }).isRequired,
  onRemoveUser: PropTypes.func.isRequired
};
