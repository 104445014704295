import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

import * as Form from 'admin/components/ui/form/main';
import AdminUiBtnComponent from 'admin/components/ui/btn';
import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminUiInputComponent from 'admin/components/ui/form/input';

import { dateTimeFormat } from 'lib/date_time';

export default class AdminUsersListFilterComponent extends React.Component {
  filtersSetter = ({ target }) => {
    const { name: key, value } = target;
    const { filters, handleChange } = this.props;
    const newFilter = { ...filters };
    newFilter[key] = value;
    if (!value) delete newFilter[key];
    handleChange(newFilter);
  }

  handleSelect = ({ name, value }) => {
    const { locale } = this.props;
    if (name === 'birthday' && value) {
      this.filtersSetter({
        target: {
          name,
          value: moment(value).format(dateTimeFormat('date_full_2', locale))
        }
      });
    }

    this.filtersSetter({ target: { name, value } });
  }

  handleClearFilter = () => {
    const { handleChange } = this.props;
    handleChange();
  }

  render() {
    const {
      filters,
      countries,
      locale
    } = this.props;
    return (
      <tr className="text-center">
        <th />
        <th style={ { width: '50px' } }>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="id"
            value={ filters.id || '' }
          />
        </th>
        <th>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="email"
            value={ filters.email || '' }
            className="form-control"
          />
        </th>
        <th>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="nick"
            value={ filters.nick || '' }
            className="form-control"
          />
        </th>
        <th>
          <Form.Datepicker
            value={ filters.birthday || '' }
            onChange={ this.handleSelect }
            onChangeRaw={ this.filtersSetter }
            dateFormat={ dateTimeFormat('date_full_2', locale) }
            type="text"
            name="birthday"
          />
        </th>
        <th>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="fullname"
            value={ filters.fullname || '' }
            className="form-control"
          />
        </th>
        <th>
          <Form.AutocompleteCountries
            name="country"
            countryCode={ filters.country || '' }
            onCountrySelected={ this.handleSelect }
            countries={ countries }
          />
        </th>
        <th>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="skype"
            value={ filters.skype || '' }
            className="form-control"
          />
        </th>
        <th>
          <AdminUiInputComponent
            onChange={ this.filtersSetter }
            type="text"
            name="discord_id"
            value={ filters.discord_id || '' }
            className="form-control"
          />
        </th>
        <th style={ { width: '50px' } }>
          <AdminUiBtnComponent size="sm" mod="danger" onClick={ this.handleClearFilter }>
            <AdminUiIconComponent mod="close" adminLte />
          </AdminUiBtnComponent>
        </th>
      </tr>
    );
  }
}

AdminUsersListFilterComponent.propTypes = {
  locale: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  filters: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired
};
