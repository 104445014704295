import { translations } from 'core/locales/translations';

const DEFAULT_LOCALE = 'en';

export const i18n = (key) => {
  return (translations[window.I18n.locale] && translations[window.I18n.locale][key]) ||
          translations[DEFAULT_LOCALE][key] || key;
}

export const i18nWithDefaultLocale = key => translations[DEFAULT_LOCALE][key] || key;

export const replaceParams = (str, match) => {
  let ans = str;
  Object.keys(match).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'g');
    ans = ans.replace(re, match[key]);
  });
  return ans;
};
