// teams list constants
export const teamsList = {

  FETCH_TEAMS_START: 'FETCH_TEAMS_START/ADMIN_TEAMS_LIST',
  UPDATE_TEAMS: 'UPDATE_TEAMS/ADMIN_TEAMS_LIST',
  FETCH_TEAMS_FAIL: 'FETCH_TEAMS_FAIL/ADMIN_TEAMS_LIST',

  FETCH_COUNTRIES: 'FETCH_COUNTRIES/ADMIN_TEAMS_LIST',

  FETCH_DISCIPLINES_START: 'FETCH_DISCIPLINES_START/ADMIN_TEAMS_LIST',
  FETCH_DISCIPLINES_OK: 'FETCH_DISCIPLINES_OK/ADMIN_TEAMS_LIST',
  FETCH_DISCIPLINES_FAIL: 'FETCH_DISCIPLINES_FAIL/ADMIN_TEAMS_LIST',

  FETCH_REGIONS_START: 'FETCH_REGIONS_START/ADMIN_TEAMS_LIST',
  FETCH_REGIONS_FAIL: 'FETCH_REGIONS_FAIL/ADMIN_TEAMS_LIST',
  FETCH_REGIONS_OK: 'FETCH_REGIONS_OK/ADMIN_TEAMS_LIST',

  UPDATE_FILTER: 'UPDATE_FILTER/ADMIN_TEAMS_LIST',
  RESET_FILTER: 'RESET_FILTER/ADMIN_TEAMS_LIST',

  UPDATE_SORTING: 'UPDATE_SORTING/ADMIN_TEAMS_LIST',
  RESET_SORTING: 'RESET_SORTING/ADMIN_TEAMS_LIST',

  CHANGE_PAGE: 'CHANGE_PAGE/ADMIN_TEAMS_LIST'
};
// edit team
export const teamEdit = Object.freeze({
  FETCH_FORM_DATA_START: 'FETCH_FORM_DATA_START/ADMIN_TEAMS_EDIT',
  FETCH_FORM_DATA_OK: 'FETCH_FORM_DATA_OK/ADMIN_TEAMS_EDIT',
  FETCH_FORM_DATA_FAIL: 'FETCH_FORM_DATA_FAIL/ADMIN_TEAMS_EDIT',
  SET_TEAM_ID: 'SET_TEAM_ID/ADMIN_TEAMS_EDIT',
  UPDATE_AVATAR: 'UPDATE_AVATAR/ADMIN_TEAMS_EDIT',

  AVATAR_SUBMIT_START: 'AVATAR_SUBMIT_START/ADMIN_TEAMS_EDIT',
  AVATAR_SUBMIT_FAIL: 'AVATAR_SUBMIT_FAIL/ADMIN_TEAMS_EDIT',
  AVATAR_SUBMIT_OK: 'AVATAR_SUBMIT_OK/ADMIN_TEAMS_EDIT',
  DARK_THEME_AVATAR_SUBMIT_OK: 'DARK_THEME_AVATAR_SUBMIT_OK/ADMIN_TEAMS_EDIT'
});

// memberships
export const memberships = {
  FETCH_MEMBERS_START: 'FETCH_MEMBERS_START/ADMIN_TEAMS_MEMBERSHIPS',
  FETCH_MEMBERS_OK: 'FETCH_MEMBERS_OK/ADMIN_TEAMS_MEMBERSHIPS',
  FETCH_MEMBERS_FAIL: 'FETCH_MEMBERS_FAIL/ADMIN_TEAMS_MEMBERSHIPS',

  UPDATE_SORTING: 'UPDATE_SORTING/ADMIN_TEAMS_MEMBERSHIPS',
  RESET_SORTING: 'RESET_SORTING/ADMIN_TEAMS_MEMBERSHIPS',
  UPDATE_MEMBERS: 'UPDATE_MEMBERS/ADMIN_TEAMS_MEMBERSHIPS',
  SET_TEAM_ID: 'SET_TEAM_ID/ADMIN_TEAMS_MEMBERSHIPS',

  FETCH_ROLES_CHANGE_START: 'FETCH_ROLES_CHANGE_START/ADMIN_TEAMS_MEMBERSHIPS',
  FETCH_ROLES_CHANGE_OK: 'FETCH_ROLES_CHANGE_OK/ADMIN_TEAMS_MEMBERSHIPS',
  FETCH_ROLES_CHANGE_FAIL: 'FETCH_ROLES_CHANGE_FAIL/ADMIN_TEAMS_MEMBERSHIPS',

  SHOW_EXCHANGE_MODAL: 'SHOW_EXCHANGE_MODAL/ADMIN_TEAMS_MEMBERSHIPS',
  CLOSE_EXCHANGE_MODAL: 'CLOSE_EXCHANGE_MODAL/ADMIN_TEAMS_MEMBERSHIPS',
  PROPOSAL_NEW: 'PROPOSAL_NEW/ADMIN_TEAMS_MEMBERSHIPS',
  PROPOSAL_DELETE: 'PROPOSAL_DELETE/ADMIN_TEAMS_MEMBERSHIPS',
  CLOSE_MODALS: 'CLOSE_MODALS/ADMIN_TEAMS_MEMBERSHIPS',
  SHOW_ERROR_MODAL: 'SHOW_ERROR_MODAL/ADMIN_TEAMS_MEMBERSHIPS',
  UPDATE_REGION_ID: 'UPDATE_REGION_ID/ADMIN_TEAMS_MEMBERSHIPS'
};

export const filters = Object.freeze({
  BY_ID: 'by_id',
  BY_GAME_ID: 'by_game_id',
  BY_DISCIPLINE: 'by_discipline',
  BY_REGION: 'by_region',
  BY_CAPTAIN_NICK: 'by_captain_nick',
  BY_NAME: 'by_name',
  BY_CLANTAG: 'by_clantag',
  BY_COUNTRY: 'by_country'
});

export const sort = Object.freeze({
  ID: 'id',
  GAME_ID: 'game_id',
  NAME: 'name',
  CLANTAG: 'clantag',
  COUNTRY: 'country'
});
