export default function createAvatarFormData(options) {
  const { avatarBase64, isDarkImage = false } = options;

  if (!avatarBase64) return null;

  const avatarType = avatarBase64.type.split('/').pop();
  const avatarData = new FormData();

  avatarData.append('file', avatarBase64, `newAvatar.${avatarType}`);
  avatarData.append('is_dark_image', isDarkImage.toString());
  return avatarData;
}
