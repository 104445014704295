import React from 'react';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, change
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';


import AdminUiBoxComponent from 'admin/components/ui/box';
import * as Form from 'admin/components/ui/form/main';
import * as formGroup from 'admin/helpers/redux_form/form_groups';
import AdminUiCropperComponent from 'admin/components/ui/cropper';
import AdminUiBtnComponent from 'admin/components/ui/btn';

import * as validate from 'admin/helpers/redux_form/validations';
import { i18n, replaceParams } from 'core/services/i18n';
import { dateTimeFormat } from 'lib/date_time';

const FORM_NAME = 'AdminUsersNewForm';
const required = validate.required(i18n('team.validates.required'));
const emailValidate = validate.email(i18n('simple_form.validates.user.email.format'));
const nickNameLengthValidate = validate.stringLength(
  2, 255,
  replaceParams(i18n('simple_form.validates.user.nick_name.format'), { min: 2, max: 255 })
);
const firstNameLengthValidate = validate.stringLength(
  2, 255,
  replaceParams(i18n('simple_form.validates.user.first_name.format'), { min: 2, max: 255 })
);
const lastNameLengthValidate = validate.stringLength(
  2, 255,
  replaceParams(i18n('simple_form.validates.user.last_name.format'), { min: 2, max: 255 })
);
const discardIdLengthValidate = validate.stringLength(
  2, 255,
  replaceParams(i18n('simple_form.validates.user.discord_id.format'), { min: 2, max: 255 })
);
const skypeAccountValidate = validate.skypeAccount(
  replaceParams(i18n('simple_form.validates.user.skype.format'), { min: 6, max: 32 })
);

const linkValidateVk = validate.vkLink(i18n('simple_form.validates.user.vk_link.format'));
const linkValidateFb = validate.facebookLink(i18n('simple_form.validates.user.fb_link.format'));
const linkValidateTw = validate.twitterLink(i18n('simple_form.validates.user.tw_link.format'));
const linkValidateYt = validate.youtubeLink(i18n('simple_form.validates.user.yt_link.format'));

class AdminUsersFormContainer extends React.Component {
  render() {
    const {
      locale,
      countries,
      avatarSrc,
      actionsLogUrl,
      activityLogsUrl,
      pristine,
      submitting,
      invalid,
      handleSetAvatar,
      handleSubmit, /** handleSubmit prop from redux-form HOC */
      handleSubmitForm, /** prop from parent container */
      countryCode,
      handleCloseAlert,
      notification,
      isErrored,
      resetCity,
      updateCountryByCity,
      // eslint-disable-next-line camelcase,react/prop-types
      initialValues: { identity_providers = [] }
    } = this.props;
    const isUserRegisterViaSocial = identity_providers.length !== 0;
    return (
      <AdminUiBoxComponent>
        { notification && (
          <div className={ classNames('flash-alert alert fade in', !isErrored ? 'alert-success' : 'alert-danger') }>
            <button className="close" onClick={ handleCloseAlert } data-dismiss="alert" type="button">×</button>
            { notification }
          </div>
        ) }
        <Form.Main onSubmit={ handleSubmit(handleSubmitForm) }>
          <div className="box-body">
            <div className="row">
              <div className="col-sm-5">
                <Field
                  name="email"
                  label={ i18n('simple_form.labels.user.email') }
                  component={ input => (
                    <React.Fragment>
                      {formGroup.renderInput(input)}
                      {isUserRegisterViaSocial
                      && (
                      <span style={ { marginBottom: '5px', display: 'inline-block' } }>
                        {`${i18n('simple_form.labels.user.notification_authorized')} ${identity_providers[0]}`}
                      </span>
                      )}
                    </React.Fragment>
                  ) }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.email') }
                  validate={ emailValidate }
                  disabled={ isUserRegisterViaSocial }
                />
                <Field
                  name="nick"
                  label={ i18n('simple_form.labels.user.nick') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.nick') }
                  required
                  validate={ [required, nickNameLengthValidate] }
                />
                <Field
                  name="first_name"
                  label={ i18n('simple_form.labels.user.first_name') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.first_name') }
                  validate={ firstNameLengthValidate }
                />
                <Field
                  name="last_name"
                  label={ i18n('simple_form.labels.user.last_name') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.last_name') }
                  validate={ lastNameLengthValidate }
                />
                <Field
                  name="gender"
                  label={ i18n('simple_form.labels.user.gender') }
                  component={ formGroup.renderRadio }
                  options={ [
                    { label: i18n('simple_form.options.user.gender.male'), value: 'male' },
                    { label: i18n('simple_form.options.user.gender.female'), value: 'female' },
                    { label: i18n('simple_form.options.user.gender.other'), value: 'other' }] }
                />
                <Field
                  name="birthday"
                  label={ i18n('simple_form.labels.user.birthday') }
                  placeholder={ i18n('simple_form.placeholders.user.birthday') }
                  component={ formGroup.renderDatePicker }
                  dateFormat={ dateTimeFormat('date_full_2', locale) }
                />
                <Field
                  name="country"
                  label={ i18n('simple_form.labels.user.country') }
                  component={ formGroup.renderSelectCountry }
                  options={ countries }
                  placeholder={ i18n('simple_form.placeholders.user.country') }
                  onChangeCountry={ resetCity }
                  required
                  validate={ required }
                />
                <Field
                  name="phone_number"
                  label={ i18n('simple_form.labels.user.phone_number') }
                  placeholder={ i18n('simple_form.placeholders.user.phone_number') }
                  component={ formGroup.renderInput }
                  type="text"
                />
                <Field
                  name="phone_number_verified"
                  label={ i18n('simple_form.labels.user.phone_number_verified') }
                  component={ formGroup.renderCheckbox }
                  type="text"
                />
                <Field
                  name="uid"
                  label={ i18n('simple_form.labels.user.uid') }
                  component={ formGroup.renderInput }
                  placeholder={ i18n('simple_form.placeholders.user.country') }
                  type="text"
                  disabled
                />
                <Field
                  name="edit_auth_user_url"
                  label={ i18n('simple_form.labels.user.country') }
                  component={ ({ input: { value } }) => (
                    <a href={ value } rel="noopener noreferrer" target="_blank">{ value }</a>) }
                  placeholder={ i18n('simple_form.placeholders.user.country') }
                />
              </div>
              <div className="col-sm-5 col-sm-offset-1">
                <div className="form-group">
                  <p className="form-label">{ i18n('simple_form.labels.user.logo') }</p>
                  <AdminUiCropperComponent
                    src={ avatarSrc }
                    callBackFunc={ handleSetAvatar }
                  />
                </div>
                <Field
                    name="university_name"
                    label="University"
                    component={ formGroup.renderInput }
                    type="text"
                    extraComponent={
                      <Field
                          name="edit_student_id_url"
                          component={ ({ input: { value } }) => (
                              value ?
                                  <a href={ value } rel="noopener noreferrer" target="_blank">{ 'Link to document' }</a>
                                  : null
                            )
                          }
                      />
                    }
                    disabled
                />
                <Field
                  name="discord_id"
                  label="Discord ID"
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.discord') }
                  validate={ discardIdLengthValidate }
                />
                <Field
                  name="skype"
                  label={ i18n('simple_form.labels.user.skype') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.skype') }
                  validate={ [skypeAccountValidate] }
                />
                <Field
                  name="vk_link"
                  label={ i18n('simple_form.labels.user.vk_link') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.vk_link') }
                  validate={ linkValidateVk }
                />
                <Field
                  name="fb_link"
                  label={ i18n('simple_form.labels.user.fb_link') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.fb_link') }
                  validate={ linkValidateFb }
                />
                <Field
                  name="tw_link"
                  label={ i18n('simple_form.labels.user.tw_link') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.tw_link') }
                  validate={ linkValidateTw }
                />
                <Field
                  name="yt_link"
                  label={ i18n('simple_form.labels.user.yt_link') }
                  component={ formGroup.renderInput }
                  type="text"
                  placeholder={ i18n('simple_form.placeholders.user.yt_link') }
                  validate={ linkValidateYt }
                />
              </div>
            </div>
          </div>
          <div className="box-footer">
            <AdminUiBtnComponent
              mod="success"
              type="submit"
              disabled={ pristine || submitting || invalid }
            >
              { i18n('admin.common.save.title') }
            </AdminUiBtnComponent>
            {
              actionsLogUrl && (
                <a
                  className="btn btn-default"
                  href={ actionsLogUrl }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { i18n('admin.common.instance_logs') }
                </a>
              )
            }
            {
              activityLogsUrl && (
                <a
                  className="btn btn-default"
                  href={ activityLogsUrl }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { i18n('admin.common.activity_logs.history') }
                </a>
              )
            }
          </div>
        </Form.Main>
      </AdminUiBoxComponent>
    );
  }
}

const withReduxForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(AdminUsersFormContainer);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  const country = selector(state, 'country');
  return ({
    locale: state.intl.locale,
    countryCode: country && typeof country === 'string' && country.toUpperCase()
  });
};

const mapDispatchToProps = dispatch => ({
  resetCity: () => dispatch(change(FORM_NAME, 'city', null)),
  updateCountryByCity: ({ country_code }) => dispatch(change(FORM_NAME, 'country', country_code))
});

export default connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

AdminUsersFormContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  notification: PropTypes.string,
  isErrored: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  avatarSrc: PropTypes.string.isRequired,
  actionsLogUrl: PropTypes.string,
  activityLogsUrl: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  countryCode: PropTypes.string,
  handleSubmitForm: PropTypes.func.isRequired, /** prop from parent container */
  handleSubmit: PropTypes.func.isRequired, /** handleSubmit prop from redux-form HOC */
  handleSetAvatar: PropTypes.func.isRequired,
  handleCloseAlert: PropTypes.func,
  resetCity: PropTypes.func.isRequired,
  updateCountryByCity: PropTypes.func.isRequired
};
AdminUsersFormContainer.defaultProps = {
  actionsLogUrl: null,
  activityLogsUrl: null,
  notification: '',
  countryCode: null,
  handleCloseAlert: null
};
