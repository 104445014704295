import React from 'react';
import PropTypes from 'prop-types';

class AdminUiInputComponent extends React.Component {
  render() {
    const {
      name,
      value = '',
      onChange,
      type,
      disabled
    } = this.props;

    return (
      <input
        className="form-control"
        type={ type }
        value={ value }
        name={ name }
        onChange={ onChange }
        disabled={ disabled }
        { ...this.props }
      />
    );
  }
}

AdminUiInputComponent.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
AdminUiInputComponent.defaultProps = {
  disabled: false
};

export default AdminUiInputComponent;
