import React from 'react';
import PropTypes from 'prop-types';

import AdminUiListSortCellComponent from 'admin/components/ui/list/sort_cell';

import { i18n } from 'core/services/i18n';
import { sort as sortNames } from 'constants/admin/teams';

class AdminTeamsListHeaderComponent extends React.PureComponent {
  render() {
    const {
      sorting,
      handleSort,
      resetSorting
    } = this.props;
    return (
      <tr className="text-center">
        <th>
          { i18n('simple_form.labels.team.logo') }
        </th>
        <AdminUiListSortCellComponent
          handleSort={ handleSort }
          sorting={ sorting }
          title={ i18n('simple_form.labels.team.id') }
          name={ sortNames.ID }
          key={ sortNames.ID }
        />
        <AdminUiListSortCellComponent
          handleSort={ handleSort }
          sorting={ sorting }
          title={ i18n('simple_form.labels.team.game_id') }
          name={ sortNames.GAME_ID }
          key={ sortNames.GAME_ID }
        />
        <th>
          { i18n('simple_form.labels.team.discipline') }
        </th>
        <th>
          { i18n('simple_form.labels.team.region') }
        </th>
        <th>
          { i18n('simple_form.labels.team.captain') }
        </th>
        <AdminUiListSortCellComponent
          handleSort={ handleSort }
          sorting={ sorting }
          title={ i18n('simple_form.labels.team.name') }
          name={ sortNames.NAME }
          key={ sortNames.NAME }
        />
        <AdminUiListSortCellComponent
          handleSort={ handleSort }
          sorting={ sorting }
          title={ i18n('simple_form.labels.team.clantag') }
          name={ sortNames.CLANTAG }
          key={ sortNames.CLANTAG }
        />
        <AdminUiListSortCellComponent
          handleSort={ handleSort }
          sorting={ sorting }
          title={ i18n('simple_form.labels.team.country') }
          name={ sortNames.COUNTRY }
          key={ sortNames.COUNTRY }
        />
        <th>
          <button className="btn btn-sm btn-danger" type="button" onClick={ resetSorting }>
            <i className="fa fa-close" />
          </button>
        </th>
      </tr>
    );
  }
}

AdminTeamsListHeaderComponent.propTypes = {
  sorting: PropTypes.objectOf(PropTypes.oneOf(['desc', 'asc'])).isRequired,
  handleSort: PropTypes.func.isRequired,
  resetSorting: PropTypes.func.isRequired
};

export default AdminTeamsListHeaderComponent;
