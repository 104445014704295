export const ADMIN_AUTOSUGGEST_THEME = {
  container: 'select2-container select2-container--default select2-container--no-arrow',
  containerOpen: 'select2-container--open',
  input: 'form-control',
  suggestionsContainer: 'select2-dropdown select2-dropdown--below',
  suggestionsList: 'select2-results select2-results__options',
  suggestion: 'select2-results__option',
  suggestionFirst: 'select2-results__option',
  suggestionHighlighted: 'select2-results__option--highlighted',
  sectionContainer: 'select2-results__options'
};
