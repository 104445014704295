import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import AdminUiBoxComponent from 'admin/components/ui/box';
import AdminUiBtnComponent from 'admin/components/ui/btn';
import AdminTeamsListHeaderComponent from 'admin/components/teams/list/header';
import AdminTeamsListFilterComponent from 'admin/components/teams/list/filter';
import AdminTeamsListItemComponent from 'admin/components/teams/list/item';
import AdminUiListLoader from 'admin/components/ui/list/loader';
import AdminUiListEmpty from 'admin/components/ui/list/empty';

import { i18n } from 'core/services/i18n';
import { ITEMS_PER_PAGE as displaySettings } from 'constants/index';

class AdminTeamsListComponent extends Component {
  render() {
    const {
      sorting,
      filter,
      disciplines,
      regionsByDiscipline,
      teams,
      adminPerPage,
      page,
      total,
      handleSort,
      handleFilterChange,
      resetFilter,
      resetSorting,
      handlePageChange,
      handleItemsPerPage,
      isLoadingTeams,
      locale = 'en'
    } = this.props;
    return (
      <AdminUiBoxComponent>
        <div className="box-body table-responsive">
          <table className="table table-condensed table-bordered table-striped">
            <thead>
              <AdminTeamsListHeaderComponent
                sorting={ sorting }
                handleSort={ handleSort }
                resetSorting={ resetSorting }
              />
              <AdminTeamsListFilterComponent
                filter={ filter }
                disciplines={ disciplines }
                regionsByDiscipline={ regionsByDiscipline }
                handleFilterChange={ handleFilterChange }
                resetFilter={ resetFilter }
              />
            </thead>
            <tbody>
              { isLoadingTeams && <AdminUiListLoader colSpan={ 10 } /> }
              {
                teams.length && !isLoadingTeams
                  ? teams.map(team => <AdminTeamsListItemComponent key={ team.id } team={ team } locale={ locale } />)
                  : !isLoadingTeams
                  && <AdminUiListEmpty colSpan={ 10 } text={ i18n('admin.teams.index.not_found') } />
              }
            </tbody>
          </table>
        </div>
        <div className="box-footer">
          <ReactPaginate
            pageCount={ total / adminPerPage }
            forcePage={ page - 1 }
            pageRangeDisplayed={ 5 }
            marginPagesDisplayed={ 1 }
            containerClassName="pagination pagination-sm no-margin pull-right"
            breakClassName="disabled"
            activeClassName="active"
            previousLabel="«"
            nextLabel="»"
            breakLabel={ <span>…</span> }
            onPageChange={ handlePageChange }
          />
          { displaySettings.map(count => (
            <AdminUiBtnComponent
              key={ `paginate-per-${count}` }
              size="xs"
              onClick={ () => {
                handleItemsPerPage(count);
              } }
              mod={ adminPerPage === count ? 'primary' : 'default' }
              active={ adminPerPage === count }
            >
              { count }
            </AdminUiBtnComponent>))
           }
        </div>
      </AdminUiBoxComponent>
    );
  }
}

AdminTeamsListComponent.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingTeams: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  sorting: PropTypes.objectOf(PropTypes.oneOf(['desc', 'asc'])).isRequired,
  page: PropTypes.number.isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.object).isRequired,
  regionsByDiscipline: PropTypes.arrayOf(PropTypes.object).isRequired,
  adminPerPage: PropTypes.number.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleItemsPerPage: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  resetSorting: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};


export default AdminTeamsListComponent;
