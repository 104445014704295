import React from 'react';
import PropTypes from 'prop-types';

import AdminUiFormAutocompleteCountriesComponent from 'admin/components/ui/form/autocomplete/countries';
import AdminUiFormAutocompleteMultiComponent from 'admin/components/ui/form/select/multi';
import AdminUiInputComponent from 'admin/components/ui/form/input';

import { filters as filtersName } from 'constants/admin/teams';

class AdminTeamsListFilterComponent extends React.PureComponent {
  handleChange = ({ name, value, tagName }) => {
    const { handleFilterChange } = this.props;
    handleFilterChange({
      target: {
        name,
        value,
        tagName
      }
    });
  };

  render() {
    const {
      filter: {
        by_id: byId = '',
        by_game_id: byGameId = '',
        by_discipline: byDiscipline = '',
        by_region: byRegion = '',
        by_captain_nick: byCaptainNick = '',
        by_name: byName = '',
        by_clantag: byClantag = '',
        by_country: byCountry = ''
      },
      disciplines = [],
      regionsByDiscipline = [],
      resetFilter,
      handleFilterChange
    } = this.props;
    return (
      <tr className="text-center">
        <th />
        <th style={ { width: '50px' } }>
          <AdminUiInputComponent
            name={ filtersName.BY_ID }
            type="text"
            value={ byId }
            onChange={ handleFilterChange }
          />
        </th>
        <th>
          <AdminUiInputComponent
            name={ filtersName.BY_GAME_ID }
            type="text"
            value={ byGameId }
            onChange={ handleFilterChange }
          />
        </th>
        <th>
          <AdminUiFormAutocompleteMultiComponent
            name={ filtersName.BY_DISCIPLINE }
            suggestions={ disciplines || [] }
            onSelect={ this.handleChange }
            value={ byDiscipline }
            className="form-control"
          />
        </th>
        <th>
          <AdminUiFormAutocompleteMultiComponent
            name={ filtersName.BY_REGION }
            className="form-control"
            disabled={ !regionsByDiscipline.length }
            suggestions={ regionsByDiscipline }
            value={ byRegion }
            onSelect={ this.handleChange }
          />
        </th>
        <th>
          <AdminUiInputComponent
            name={ filtersName.BY_CAPTAIN_NICK }
            type="text"
            value={ byCaptainNick }
            onChange={ handleFilterChange }
          />
        </th>
        <th>
          <AdminUiInputComponent
            name={ filtersName.BY_NAME }
            type="text"
            value={ byName }
            onChange={ handleFilterChange }
          />
        </th>
        <th>
          <AdminUiInputComponent
            name={ filtersName.BY_CLANTAG }
            type="text"
            value={ byClantag }
            onChange={ handleFilterChange }
          />
        </th>
        <th>
          <AdminUiFormAutocompleteCountriesComponent
            name={ filtersName.BY_COUNTRY }
            countryCode={ byCountry }
            onCountrySelected={ this.handleChange }
          />
        </th>
        <th>
          <button className="btn btn-sm btn-danger" type="button" onClick={ resetFilter }>
            <i className="fa fa-close" />
          </button>
        </th>
      </tr>
    );
  }
}

AdminTeamsListFilterComponent.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    by_id: PropTypes.string,
    by_game_id: PropTypes.string,
    by_discipline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    by_region: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    by_captain_nick: PropTypes.string,
    by_name: PropTypes.string,
    by_clantag: PropTypes.string,
    by_country: PropTypes.string
  }).isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.object).isRequired,
  regionsByDiscipline: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AdminTeamsListFilterComponent;
