import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { dateTimeFormat } from 'lib/date_time';
// dateTimeFormat('date_full_2', locale)

import AdminUiListLinkEditComponent from 'admin/components/ui/list/link_edit';

export default class AdminUsersListItemComponent extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    email: PropTypes.string,
    nick: PropTypes.string.isRequired,
    birthday: PropTypes.string,
    fullName: PropTypes.string,
    countryName: PropTypes.string.isRequired,
    skype: PropTypes.string,
    discordId: PropTypes.string,
    logo: PropTypes.string.isRequired,
    editLink: PropTypes.string.isRequired
  }

  static defaultProps = {
    email: null,
    birthday: null,
    fullName: null,
    skype: null,
    discordId: null
  }

  render() {
    const {
      locale,
      id,
      email,
      nick,
      birthday,
      fullName,
      countryName,
      skype,
      discordId,
      logo,
      editLink
    } = this.props;
    return (
      <tr className="text-center">
        <td>
          <img alt="" src={ logo } />
        </td>
        <td>{ id }</td>
        <td>{ email }</td>
        <td>{ nick }</td>
        <td>{ birthday && moment(birthday).format(dateTimeFormat('date_full_2', locale)) }</td>
        <td>{ fullName }</td>
        <td>{ countryName }</td>
        <td>{ skype }</td>
        <td>{ discordId }</td>
        <td>
          <AdminUiListLinkEditComponent url={ editLink } />
        </td>
      </tr>
    );
  }
}
