import PropTypes from 'prop-types';
import React from 'react';

import AdminUiBoxComponent from 'admin/components/ui/box';
import AdminUiListEmpty from 'admin/components/ui/list/empty';
import AdminUiListLoader from 'admin/components/ui/list/loader';
import * as AdminUiPaginationComponent from 'admin/components/ui/pagination';
import AdminUsersListItemComponent from 'admin/components/users/list/item';
import AdminUsersListHeaderComponent from 'admin/components/users/list/header';
import AdminUsersListFilterComponent from 'admin/components/users/list/filter';

import { i18n } from 'core/services/i18n';


const AdminUsersListComponent = ({
  locale,
  users,
  total,
  page,
  perPage,
  countries,
  filters,
  handleFiltersChange,
  handlePageChange,
  handlePerPageChange,
  isLoading,
  error
}) => (
  <AdminUiBoxComponent>
    <div className="box-body table-responsive">
      <table className="table table-condensed table-bordered table-striped">
        <thead>
          <AdminUsersListHeaderComponent />
          <AdminUsersListFilterComponent
            countries={ countries }
            filters={ filters }
            handleChange={ handleFiltersChange }
            locale={ locale }
          />
        </thead>
        <tbody>
          { isLoading && <AdminUiListLoader colSpan={ 11 } /> }
          { !isLoading && users.map(
            user => <AdminUsersListItemComponent locale={ locale } key={ user.id } { ...user } />
          ) }
          { !users.length && !error && !isLoading
            && <AdminUiListEmpty colSpan={ 11 } text={ i18n('admin.users.empty_list.no_users') } /> }
          { error && <AdminUiListEmpty colSpan={ 11 } text={ error } /> }
        </tbody>
      </table>
    </div>
    <div className="box-footer">
      <AdminUiPaginationComponent.Pages
        page={ page }
        perPage={ perPage }
        total={ total }
        handleChange={ handlePageChange }
      />
      <AdminUiPaginationComponent.PerPage
        perPage={ perPage }
        handleChange={ handlePerPageChange }
      />
    </div>
  </AdminUiBoxComponent>
);

AdminUsersListComponent.propTypes = {
  locale: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  filters: PropTypes.shape({}).isRequired,
  total: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nick: PropTypes.string
  })),
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handlePerPageChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

AdminUsersListComponent.defaultProps = {
  total: 0,
  users: [],
  error: ''
};

export default AdminUsersListComponent;
