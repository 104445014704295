import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from 'core/services/i18n';

import AdminUiIconComponent from 'admin/components/ui/icon';
import AdminTmsDashboardBattleContainer from './battle';

export default class AdminTmsDashboardBattlesContainer extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired
  }

  constructor() {
    super();

    this.state = {
      isFetching: true,
      battles: []
    };
  }

  componentDidMount() {
    this.updateList();
  }

  getBattles = () => {
    const { url } = this.props;
    return $.ajax({
      url,
      dataType: 'json',
      cache: false
    });
  }

  updateList = () => {
    this.getBattles().done((result) => {
      this.setState({
        battles: result,
        isFetching: false
      });
    });
  }

  render() {
    const {
      isFetching,
      battles
    } = this.state;

    if (isFetching) { return <AdminUiIconComponent spin />; }
    if (battles.length === 0) { return (<div>No battles</div>); }

    return (
      <table className="table table-condensed">
        <thead>
          <tr>
            <th>{ i18n('simple_form.labels.tms_battle.id') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.position') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.tournament_group_id') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.started_at') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.map_id') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.tournament_referee_id') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.status') }</th>
            <th>{ i18n('simple_form.labels.tms_battle.call_referee_source') }</th>
            <th colSpan="2" />
          </tr>
        </thead>
        <tbody>
          { battles.map(battle => <AdminTmsDashboardBattleContainer key={ battle.id } { ...battle } />) }
        </tbody>
      </table>
    );
  }
}
