import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AdminUsersListComponent from 'admin/components/users/list/list';

import * as actionsAdminUsers from 'actions/admin/users/list';
import * as actionsAdmin from 'actions/admin';
import { dateTimeFormat } from 'lib/date_time';

class AdminUsersListContainer extends React.Component {
  handlePerPageChange = (perPage) => {
    const { actions } = this.props;
    actions.changePerPage(perPage);
    actions.changePage(1);
    actions.fetchUsers();
  }

  handlePageChange = (newPage) => {
    const { actions } = this.props;
    actions.changePage(newPage);
    actions.fetchUsers();
  }

  handleFiltersChange = (newFilters = {}) => {
    const { actions } = this.props;
    // const processFilter = { ...newFilters };
    // if processFilter.birthday
    // Object.keys(newFilters);
    dateTimeFormat();
    actions.changeFilters(newFilters);
    actions.changePage(1);
    actions.fetchUsers();
  }

  render() {
    const {
      countries,
      filters,
      total,
      users,
      page,
      perPage,
      isLoading,
      error,
      locale
    } = this.props;
    return (
      <AdminUsersListComponent
        locale={ locale }
        countries={ countries }
        filters={ filters }
        users={ users }
        total={ total }
        page={ page }
        perPage={ perPage }
        isLoading={ isLoading }
        handlePerPageChange={ this.handlePerPageChange }
        handlePageChange={ this.handlePageChange }
        handleFiltersChange={ this.handleFiltersChange }
        error={ error }
      />
    );
  }
}

const mapStateToProps = state => ({
  locale: state.intl.locale,
  countries: state.adminUsersList.countries,
  filters: state.adminUsersList.filters,
  total: state.adminUsersList.total,
  users: state.adminUsersList.result,
  page: state.adminUsersList.page,
  isLoading: state.adminUsersList.isLoading,
  error: state.adminUsersList.error,
  perPage: state.admin.perPage
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actionsAdminUsers, ...actionsAdmin }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersListContainer);

AdminUsersListContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nick: PropTypes.string
  })),
  filters: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })),
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  actions: PropTypes.shape({
    fetchUsers: PropTypes.func.isRequired,
    changeFilters: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changePerPage: PropTypes.func.isRequired
  }).isRequired
};

AdminUsersListContainer.defaultProps = {
  countries: [],
  filters: {},
  total: 0,
  users: [],
  page: 1,
  error: ''
};
