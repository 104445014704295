import { SubmissionError } from 'redux-form';
import * as users from 'constants/admin/users';
import fetchApi from 'admin/api/api';
import createUserFormData from 'admin/helpers/users/create_user_form_data';
import createAvatarFormData from 'admin/helpers/utils/create_avatar_form_data';

export const showAlert = notification => ({ type: users.SHOW_ALERT_ADMIN_USERS_NEW, payload: notification });
export const closeAlert = () => ({ type: users.CLOSE_ALERT_ADMIN_USERS_NEW });

export const fetchDataStart = () => ({ type: users.START_FETCH_DATA_ADMIN_USERS_NEW });
export const fetchDataOk = payload => ({ type: users.OK_FETCH_DATA_ADMIN_USERS_NEW, payload });
export const fetchDataFail = () => ({ type: users.FAIL_FETCH_DATA_ADMIN_USERS_NEW });
export const fetchData = () => async (dispatch, getState) => {
  dispatch(fetchDataStart());
  const { locale } = getState().intl;
  try {
    const data = await fetchApi(`/${locale}/users/new.json`, { method: 'GET' });
    dispatch(fetchDataOk(data));
  } catch ({ networkError, errors }) {
    const notification = errors || networkError || 'Something wrong';
    dispatch(fetchDataFail());
    dispatch(showAlert(notification));
  }
};

export const updateBase64Avatar = avatarBase64 => (
  { type: users.UPDATE_BASE64_AVATAR_ADMIN_USERS_NEW, payload: avatarBase64 }
);

export const submitAvatarStart = () => ({ type: users.START_SUBMIT_AVATAR_ADMIN_USERS_NEW });
export const submitAvatarOK = () => ({ type: users.OK_SUBMIT_AVATAR_ADMIN_USERS_NEW });
export const submitAvatarFail = () => ({ type: users.FAIL_SUBMIT_AVATAR_ADMIN_USERS_NEW });
export const submitAvatar = ({ editUrl, avatarUploadPath }, base64Avatar) => (dispatch) => {
  dispatch(submitAvatarStart());
  fetchApi(avatarUploadPath, { method: 'POST', body: createAvatarFormData({ avatarBase64: base64Avatar }) })
    .then(() => {
      dispatch(submitAvatarOK());
      window.location.replace(editUrl);
    })
    .catch(({ networkError, errors }) => {
      dispatch(submitAvatarFail());
      dispatch(showAlert(networkError || errors || 'Something wrong'));
    });
};

export const submitFormStart = () => ({ type: users.START_SUBMIT_FORM_ADMIN_USERS_NEW });
export const submitFormOK = payload => ({ type: users.OK_SUBMIT_FORM_ADMIN_USERS_NEW, payload });
export const submitFormFail = notification => ({ type: users.FAIL_SUBMIT_FORM_ADMIN_USERS_NEW, payload: notification });
export const submitForm = user => (dispatch, getState) => {
  const { intl: { locale }, adminUserNew: { base64Avatar } } = getState();
  dispatch(submitFormStart());
  return fetchApi(`/${locale}/users.json`, { method: 'POST', body: createUserFormData(user) })
    .then((data) => {
      const { edit_url: editUrl, avatar_upload_path: avatarUploadPath } = data;
      dispatch(submitFormOK());
      dispatch(closeAlert());
      if (base64Avatar) {
        dispatch(submitAvatar({ editUrl, avatarUploadPath }, base64Avatar));
      } else {
        window.location.replace(editUrl);
      }
    }).catch(({ networkError, errors }) => {
      if (networkError || !errors) {
        dispatch(submitFormFail());
        dispatch(showAlert(networkError || 'Something wrong'));
      } else {
        dispatch(submitFormFail());
      }
      if (errors) {
        throw new SubmissionError({
          ...errors,
          _error: 'Submit failed!'
        });
      }
    });
};
