import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminUiCropperComponent from './cropper';
import createAvatarFormData from '../../helpers/utils/create_avatar_form_data';
import fetchApi from '../../api/api';


export default class AdminAvatarCropperComponent extends Component {
  constructor(props) {
    super(props);
    const { logo: { src, id } } = props;
    this.state = {
      imagePath: src,
      imageId: id,
      status: ''
    };
  }

  handleAvatarSubmit = async (data) => {
    if (data !== null) {
      return this.uploadImageToServer(data);
    }
    return this.deleteImage();
  };


  // eslint-disable-next-line consistent-return
  uploadImageToServer = async (avatarBase64) => {
    const { imageId } = this.state;
    const {
      isDarkImage = false, locale, teamId
    } = this.props;

    try {
      const response = await fetchApi(`/${locale}/teams/${teamId}/avatars/${imageId}`, {
        method: 'PATCH',
        body: createAvatarFormData({ avatarBase64, isDarkImage })
      });
      if (response.status === 'ok') {
        this.setState({ status: 'success' });
      }
      return response;
    } catch (e) {
      this.setState({ status: 'error' });
      console.warn('we get an err while loading avatar', e);
    }
  };

  // eslint-disable-next-line consistent-return
  deleteImage = async () => {
    const { imageId } = this.state;
    const {
      isDarkImage = false, locale, teamId
    } = this.props;
    try {
      const formData = new FormData();
      formData.append('is_dark_image', isDarkImage.toString());

      return await fetchApi(`/${locale}/teams/${teamId}/avatars/${imageId}`, {
        method: 'DELETE',
        body: formData
      });
    } catch (e) {
      console.warn('we get an err while deleting avatar', e);
    }
  };

  render() {
    const { imagePath, status } = this.state;

    return (
      <React.Fragment>
        <AdminUiCropperComponent src={ imagePath } callBackFunc={ this.handleAvatarSubmit } status={ status } />
      </React.Fragment>

    );
  }
}

AdminAvatarCropperComponent.defaultProps = {
  isDarkImage: false
};

AdminAvatarCropperComponent.propTypes = {
  isDarkImage: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired

};
