import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'core/services/i18n';

import AdminUiBtnComponent from 'admin/components/ui/btn';
import AdminUiDisciplineIconComponent from 'admin/components/ui/discipline_icon';
import AdminUiPanelBodyComponent from 'admin/components/ui/panel_body';

import AdminTmsDashboardMatchesContainer from './matches';
import AdminTmsDashboardBattlesContainer from './battles';


export default class AdminTmsDashboardTournamentContainer extends React.Component {
  static propTypes = {
    onExpand: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    expandedTab: PropTypes.string,
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    matches_url: PropTypes.string.isRequired,
    today_matches_url: PropTypes.string.isRequired,
    protest_matches_url: PropTypes.string.isRequired,
    discipline: PropTypes.shape({
      alias: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      is_battle_royale: PropTypes.bool.isRequired
    }).isRequired,
    today_matches_count: PropTypes.number,
    matches_count: PropTypes.number,
    protest_matches_count: PropTypes.number,
    call_referee_matches_count: PropTypes.number
  };

  static defaultProps = {
    expandedTab: null,
    today_matches_count: 0,
    matches_count: 0,
    protest_matches_count: 0,
    call_referee_matches_count: 0
  };

  constructor(props) {
    super(props);

    const { expandedTab } = this.props;
    this.state = { activeTab: expandedTab };
  }

  handleTabSelect = (tab) => {
    const { activeTab } = this.state;
    const {
      id,
      onCollapse,
      onExpand
    } = this.props;

    return () => {
      const newTab = activeTab === tab ? null : tab;
      this.setState({
        activeTab: newTab
      });
      if (newTab === null) {
        onCollapse(id);
      } else {
        onExpand(id, newTab);
      }
    };
  };

  render() {
    const {
      id,
      discipline,
      url,
      full_name,
      matches_count,
      protest_matches_count,
      call_referee_matches_count,
      today_matches_count,
      today_matches_url,
      matches_url,
      protest_matches_url
    } = this.props;

    const { activeTab } = this.state;

    const protestsAndCallsCount = protest_matches_count + call_referee_matches_count;
    return (
      <div key={ id } className="panel">
        <div className="panel-heading panel-data">
          <div className="panel-box">
            <span>
              #
              { id }
              { ' ' }
            </span>
            <AdminUiDisciplineIconComponent alias={ discipline.alias } />
            <a href={ url } target="_blank" rel="noopener noreferrer">
              { full_name }
            </a>
          </div>
          <div className="text-right">
            <div className="panel-btn">
              <AdminUiBtnComponent
                onClick={ this.handleTabSelect('protestMatches') }
                pull="right"
                disabled={ protestsAndCallsCount === 0 }
                mod={ protestsAndCallsCount === 0 ? 'default' : 'danger' }
              >
                { i18n('admin.tms.dashboard.protests_and_calls') }
                :
                { ' ' }
                { protestsAndCallsCount }
              </AdminUiBtnComponent>
            </div>
            <div className="panel-btn">
              <AdminUiBtnComponent
                onClick={ this.handleTabSelect('allMatches') }
                pull="right"
                disabled={ matches_count === 0 }
                className="panel-btn"
              >
                { i18n('admin.tms.dashboard.all_matches') }
                :
                { ' ' }
                { matches_count }
              </AdminUiBtnComponent>
            </div>
            <div className="panel-btn">
              <AdminUiBtnComponent
                onClick={ this.handleTabSelect('todayMatches') }
                pull="right"
                disabled={ today_matches_count === 0 }
                className="panel-btn"
              >
                { i18n('admin.tms.dashboard.today_matches') }
                :
                { ' ' }
                { today_matches_count }
              </AdminUiBtnComponent>
            </div>
          </div>
        </div>

        <AdminUiPanelBodyComponent hidden={ activeTab !== 'todayMatches' }>
          {
            discipline.is_battle_royale ? (
              <AdminTmsDashboardBattlesContainer url={ today_matches_url } />
            ) : (
              <AdminTmsDashboardMatchesContainer url={ today_matches_url } />
            )
          }
        </AdminUiPanelBodyComponent>

        <AdminUiPanelBodyComponent hidden={ activeTab !== 'allMatches' }>
          {
            discipline.is_battle_royale ? (
              <AdminTmsDashboardBattlesContainer url={ matches_url } />
            ) : (
              <AdminTmsDashboardMatchesContainer url={ matches_url } />
            )
          }
        </AdminUiPanelBodyComponent>

        <AdminUiPanelBodyComponent hidden={ activeTab !== 'protestMatches' }>
          {
            discipline.is_battle_royale ? (
              <AdminTmsDashboardBattlesContainer url={ protest_matches_url } />
            ) : (
              <AdminTmsDashboardMatchesContainer url={ protest_matches_url } />
            )
          }
        </AdminUiPanelBodyComponent>
      </div>
    );
  }
}
